export const  imageObj = {
    1:[
        { 
            txt: '1931年9月20日中国共产党为日本帝国主义强暴占领东三省事件宣言',
            src: '221A5203.png'
        },
        { 
            txt: '1931年9月20日中国共产党为日本帝国主义强暴占领东三省事件宣言',
            src: '221A5205.png'
        },
    ],
    2:[
        { 
            txt: '中共满洲省委为日本帝国主义武装占领满洲宣言',
            src: '5G3A9580.png'
        },
        { 
            txt: '中共满洲省委为日本帝国主义武装占领满洲宣言',
            src: '5G3A9581.png'
        },
        { 
            txt: '120世纪中共满洲省委书记王立功使用的文具盒',
            src: '5G3A2273.png'
        },
    ],
    3:[
        { 
            txt: '1931年9月22日中央关于日本帝国主义强占满洲事变的决议',
            src: '221A5197.png'
        },
        { 
            txt: '1931年9月22日中央关于日本帝国主义强占满洲事变的决议',
            src: '221A5199.png'
        },
        { 
            txt: '1931年9月22日中央关于日本帝国主义强占满洲事变的决议',
            src: '221A5201.png'
        },
    ],
    4:[
        { 
            txt: '中国共产党为日本帝国主义强占东三省第二次宣言',
            src: '5G3A9596.png'
        },
        { 
            txt: '中国共产党为日本帝国主义强占东三省第二次宣言',
            src: '5G3A9596.png'
        },
        { 
            txt: '中国共产党为日本帝国主义强占东三省第二次宣言',
            src: '5G3A9596.png'
        },
    ],
    5:[
        { 
            txt: '中共满洲省委印发的《告满洲各地义勇军书》',
            src: '5G3A1905.png'
        }
    ],
    6:[
        { 
            txt: '1932年4月21日刊载有“中华苏维埃共和国临时中央政府对日战争宣言”的《红色中华》报第18期',
            src: '221A5196.png'
        }
    ],
    7:[
        { 
            txt: '宣传抗日的布告红军',
            src: '221A4779.png'
        }
    ],
    8:[
        { 
            txt: '中华民族解放先锋队对时局宣言',
            src: '221A5194.png'
        }
    ],
    9:[
        { 
            txt: '北平市学生联合会成立宣言',
            src: '5G3A9609.png'
        },
        { 
            txt: '中山大学抗日救国扩大抗日宣传特刊',
            src: '5G3A9611.png'
        },
        { 
            txt: '为反对日本并吞华北和蒋介石卖国宣言',
            src: '5G3A9614.png'
        },
        { 
            txt: '一二一二抗日救亡运动宣传大纲',
            src: '5G3A9618.png'
        },
        { 
            txt: '一二九救国运动的精神的群众周刊',
            src: '5G3A9621.png'
        },
    ],
    10:[
        { 
            txt: '1941年美国纽约KEYNOTE唱片公司出版黑人歌唱家保罗·罗伯逊演唱《起来（义勇军进行曲）》唱片',
            src: '221A5184.png'
        },
        { 
            txt: '1941年美国纽约KEYNOTE唱片公司出版黑人歌唱家保罗·罗伯逊演唱《起来（义勇军进行曲）》唱片',
            src: '221A5190.png'
        }
    ],
    11:[
        { 
            txt: '抗日传单背面的手稿抗日新歌',
            src: '5G3A9625.png'
        }
    ],
    12:[
        { 
            txt: '中共东满特委印发的朝文版《两条战线》',
            src: '5G3A1898.png'
        }
    ],
    13:[
        { 
            txt: '南满游击队队旗',
            src: '5G3A1893.png'
        },
        { 
            txt: '南满游击队袖标',
            src: '5G3A1895.png'
        }
    ],
    14:[
        { 
            txt: '北平抗日周报社出版《抗日周报》第5期',
            src: '5G3A1903.png'
        },
        { 
            txt: '海外华侨为察哈尔民众抗日同盟军募集捐款印发的快邮代电',
            src: '221A5182.png'
        },
        { 
            txt: '20世纪中共党员黄铁城使用的奥林巴斯怀表（旅顺日俄监狱旧址博物馆）',
            src: '5G3A2301.png'
        },
        { 
            txt: '宋铁岩用过的书箱',
            src: '5G3A2267.png'
        },
        { 
            txt: '宋铁岩用过的日记',
            src: '5G3A9656.png'
        }
    ],
    15:[
        { 
            txt: '《东北抗日联军统一军队建制宣言》',
            src: '5G3A2308.png'
        },
    ],
    16:[
        { 
            txt: '东北抗日联军临时指挥部分布图',
            src: '5G3A1908.png'
        },
    ],
    17:[
        { 
            txt: '东北抗日联军第三路军总指挥部号召东北人民参加抗联的布告',
            src: '5G3A1911.png'
        },
        { 
            txt: '东北抗联第3路军第3支队侦察班长史化鹏使用的行李带',
            src: '5G3A2320.png'
        },
        { 
            txt: '东北抗日联军教导旅排长张凤岐荣获的“光荣佩刀”',
            src: '5G3A2297.png'
        },
        { 
            txt: '东北抗日联军教导旅排长张凤岐荣获的“光荣佩刀”',
            src: '5G3A2298.png'
        },
        { 
            txt: '东北抗日联军第二路军指挥部印章',
            src: '5G3A2313.png'
        },
        { 
            txt: '东北抗日联军第三军党政军系统表',
            src: '5G3A1914.png'
        },
        { 
            txt: '东北抗日联军第三军第七师成立宣言',
            src: '5G3A9688.png'
        },
        { 
            txt: '东北抗联为长期抗战争取最后胜利告人民书',
            src: '5G3A9685.png'
        },
        { 
            txt: '东北抗联为长期抗战争取最后胜利告人民书',
            src: '5G3A9686.png'
        },
        { 
            txt: '成立东北抗联第八军告同胞军政各界快邮代电',
            src: '5G3A9683.png'
        },
        { 
            txt: '王学尧烈士的领尸证',
            src: '5G3A1920.png'
        },
        { 
            txt: '东北抗联第3军鸡冠山密营出土的铁雷',
            src: '5G3A2288.png'
        },
        { 
            txt: '东北抗联第3军鸡冠山密营出土的飞刀',
            src: '5G3A2329.png'
        },
        { 
            txt: '东北抗联第3军鸡冠山密营出土的飞刀',
            src: '5G3A2323.png'
        },
        { 
            txt: '东北抗联第3军鸡冠山密营出土的铁质油灯',
            src: '5G3A2348.png'
        },
        { 
            txt: '东北抗联第3军鸡冠山密营出土的机枪弹钣（含三十枚子弹及一条弹夹）',
            src: '5G3A2334.png'
        },
        { 
            txt: '东北抗联第3军鸡冠山密营出土的步枪子弹（含五枚子弹及一条弹夹）',
            src: '5G3A2344.png'
        },
        { 
            txt: '东北抗联第3军鸡冠山密营出土的手枪子弹（含十枚子弹及一条弹夹）',
            src: '5G3A2337.png'
        },
        { 
            txt: '东北抗日联军第三军暂定官兵等级制草案',
            src: '5G3A1916.png'
        },
        { 
            txt: '纪云龙编东北书店出版抗联史料《杨靖宇和抗联第一路军》',
            src: '221A5178.png'
        },
        { 
            txt: '东北抗日联军歌集',
            src: '5G3A1933.png'
        },
        { 
            txt: '南区国境警备要领（关于东北抗联第2路军周保中下属部队活动情况的报道）',
            src: '5G3A1923.png'
        },
    ],
    18:[
        { 
            txt: '东北抗联战士王济堂刺探日军情报用于掩饰身份时使用的伪满铁路《身份证明书》',
            src: '5G3A1931.png'
        },
        { 
            txt: '支援东北抗日联军的鄂伦春族陈山使用的马鞍',
            src: '5G3A9715.png'
        },
        { 
            txt: '支援东北抗日联军的鄂伦春族陈山使用的匕首',
            src: '5G3A2280.png'
        },
        { 
            txt: '支援东北抗日联军的鄂伦春族陈山使用的马具饰品（铃铛）',
            src: '5G3A2261.png'
        },
        { 
            txt: '支援东北抗日联军的鄂伦春族陈山使用的马镫',
            src: '5G3A2286.png'
        },
    ],
    19:[
        { 
            txt: '中共中央给满洲党委和全体党员信——满洲的情形和中央的任务',
            src: '221A5174.png'
        }
    ],
    20:[
        { 
            txt: '1934年9月21日《红色中华》报刊载的《中国人民对日作战的基本纲领》',
            src: '221A5168.png'
        }
    ],
    21:[
        { 
            txt: '中国苏维埃临时政府及工农红军革命军事委员会号召共同抗日宣言',
            src: '5G3A1937.png'
        }
    ],
    22:[
        { 
            txt: '柳仁编实事新闻刊行社复《统一战线后的东北义勇军》',
            src: '5G3A1941.png'
        }
    ],
    23:[
        { 
            txt: '《中国苏维埃政府、中国共产党中央为抗日救国告全体同胞书》',
            src: '221A5165.png'
        }
    ],
    24:[
        { 
            txt: '瓦窑堡会议决议《关于目前政治形势与党的任务决议》',
            src: '5G3A1943.png'
        }
    ],
    25:[
        { 
            txt: '毛泽东著《论反对日本帝国主义的策略》',
            src: '221A5158.png'
        }
    ],
    26:[
        { 
            txt: '毛、周、彭、叶等红军将领发表《为红军愿意同东北军联合抗日致东北军全体将士书》表示红军愿同东北军联合抗日',
            src: '221A5162.png'
        },
        { 
            txt: '抗战时期辽西抗日县政府“抗战到底”笔筒',
            src: '063A4019.png'
        },
        { 
            txt: '牺牲救国会员证章37227',
            src: '221A5144.png'
        },
        { 
            txt: '1936年9月毛泽东起草的《国共两党抗日救国协定草案》',
            src: '221A5135.png'
        },
        { 
            txt: '1936年9月毛泽东起草的《国共两党抗日救国协定草案》',
            src: '221A5137.png'
        },
        { 
            txt: '1936年9月毛泽东起草的《国共两党抗日救国协定草案》',
            src: '221A5138.png'
        },
        { 
            txt: '中华苏维埃人民共和国中央政府中国人民红军革命军事委员会：停战议和一致抗日通电',
            src: '221A5155.png'
        },
        { 
            txt: '1936年9月1日中共中央书记处向党内发出《关于逼蒋抗日问题的指示》',
            src: '221A5133.png'
        },
    ],
    27:[
        { 
            txt: '1936年12月30日中国人民红军总政治部印毛泽东对蒋介石二十六日宣言之谈话',
            src: '5G3A1947.png'
        }
    ],
    28:[
        { 
            txt: '毛泽东在延安全国代表会议上的结论《为争取千百万群众进入抗日民族统一战线而斗争》的手稿',
            src: '221A5115.png'
        },
        { 
            txt: '毛泽东在延安全国代表会议上的结论《为争取千百万群众进入抗日民族统一战线而斗争》的手稿',
            src: '221A5116.png'
        },
        { 
            txt: '毛泽东在延安全国代表会议上的结论《为争取千百万群众进入抗日民族统一战线而斗争》的手稿',
            src: '221A5117.png'
        },
    ],
    29:[
        { 
            txt: '西安事变后西安街头张贴的宣传画',
            src: '221A5131.png'
        },
        { 
            txt: '刊有《中共中央委员会致国民党三中全会通电》的《解放》周刊创刊号',
            src: '221A5126.png'
        },
        { 
            txt: '中共中央关于西安事变致国民党中央电',
            src: '5G3A9769.png'
        },
    ],
    30:[
        { 
            txt: '中国共产党为日军进攻芦沟桥通电',
            src: '221A5114.png'
        }
    ],
    31:[
        { 
            txt: '1937年7月14日中革军委主席团发布的关于红军进行改编准备开赴抗日前线的命令JF000642',
            src: '221A4777.png'
        }
    ],
    32:[
        { 
            txt: '1937年7月中国共产党向蒋介石提交《中共中央为公布国共合作宣言》',
            src: '221A5112.png'
        }
    ],
    33:[
        { 
            txt: '1937年7月22日邓小平记录的任弼时关于卢沟桥事件的报告',
            src: '5G3A1952.png'
        },
        { 
            txt: '1937年7月22日邓小平记录的任弼时关于卢沟桥事件的报告',
            src: '5G3A9778.png'
        },
        { 
            txt: '1937年7月22日邓小平记录的任弼时关于卢沟桥事件的报告',
            src: '5G3A9779.png'
        },
        { 
            txt: '1937年7月15日《中央关于组织抗日民族统一战线指示》',
            src: '5G3A1957.png'
        },
        { 
            txt: '1937年毛泽东题写的“抗日民族统一战线万岁”',
            src: '5G3A1960.png'
        },
    ],
    34:[
        { 
            txt: '1937年7月26日《解放》周刊第20期刊载的毛泽东发表的《论反对日本帝国主义进攻的方针、办法和前途》',
            src: '5G3A1961.png'
        },
        { 
            txt: '1937年7月26日《解放》周刊第20期刊载的毛泽东发表的《论反对日本帝国主义进攻的方针、办法和前途》',
            src: '5G3A1962.png'
        },
        { 
            txt: '1937年7月26日《解放》周刊第20期刊载的毛泽东发表的《论反对日本帝国主义进攻的方针、办法和前途》',
            src: '5G3A1963.png'
        }
    ],
    35:[
        { 
            txt: '中革军委关于红军改编为国民革命军第八路军的命令',
            src: '5G3A9784.png'
        },
        { 
            txt: '战斗画报',
            src: '5G3A9787.png'
        },
        { 
            txt: '抗日画报',
            src: '5G3A9791.png'
        },
        { 
            txt: '出动中的新四军',
            src: '5G3A9794.png'
        },
        { 
            txt: '第十八集团军1940年用证章0437',
            src: '063A4024.png'
        },
        { 
            txt: '1940年第18集团军总后勤部兵站第2办事处第5兵站收发员刘丕的印章',
            src: '063A4037.png'
        },
        { 
            txt: '1938年陆军新编第四军司令部使用的铜质证章',
            src: '063A4027.png'
        },
    ],
    36:[
        { 
            txt: '1938年中共中央代表团成员董必武在武汉使用的公文包',
            src: '063A4007.png'
        },
        { 
            txt: '收款支票',
            src: '5G3A9808.png'
        },
        { 
            txt: '1938年第十八集团军（八路军）证章',
            src: '063A4040.png'
        },
        { 
            txt: '兰州八办工作人员学习俄文时使用过的笔记本',
            src: '5G3A9819.png'
        }
    ],
    37:[
        { 
            txt: '1940年第18集团军120师司令部秘书室秘书李少言创作的《一二O师在华北》系列木刻组画----雁门关战斗',
            src: '5G3A1964.png'
        }
    ],
    38:[
        { 
            txt: '1938年6月新华日报印行的《我们对于保卫武汉与第三期抗战底意见》（复制件）----雁门关战斗',
            src: '5G3A1966.png'
        }
    ],
    39:[
        { 
            txt: '国民党五届五中全会通过的限制异党活动办法----雁门关战斗',
            src: '5G3A9834.png'
        }
    ],
    40:[
        { 
            txt: '中国共产党中央委员会为纪念抗战两周年对时局宣言----雁门关战斗',
            src: '221A5089.png'
        }
    ],
    41:[
        { 
            txt: '鲁艺木刻工作团胡一川作——坚持抗战，反对投降宣传画----雁门关战斗',
            src: '5G3A1968.png'
        }
    ],
    42:[
        { 
            txt: '解放出版社出版的抗日民族统一战线指南',
            src: '5G3A9846.png'
        },
        { 
            txt: '1940年山东新华书店出版的抗战以来投降妥协的一笔费用',
            src: '5G3A9851.png'
        },
        { 
            txt: '刊载有“纪念郑少愚同学”文章的《中国空军》第64期kjj726',
            src: '221A4751.png'
        },
        { 
            txt: '刊载有“纪念郑少愚同学”文章的《中国空军》第64期kjj726',
            src: '221A4762.png'
        },
        { 
            txt: '49 宣传抗日的布告',
            src: '221A4782.png'
        },
    ],
    43:[
        { 
            txt: '156 Kjj00742复制件：1939年新华日报馆印《毛泽东救国言论选集》',
            src: '5G3A1974.png'
        },
        { 
            txt: '上海战时读物编译社出版发行《毛泽东抗战言论集》',
            src: '5G3A1972.png'
        }
    ],
    44:[
        { 
            txt: '周恩来为皖南事变题字的《新华日报》重庆版',
            src: '221A5085.png'
        },
        { 
            txt: '南方局军事组织起草并经周恩来审议的传单《新四军皖南部队惨被围歼真像》kjj675',
            src: '221A4765.png'
        },
        { 
            txt: '毛泽东起草的中共中央军委重建新四军军部命令与谈话',
            src: '5G3A9861.png'
        },
        { 
            txt: '毛泽东起草的中共中央军委重建新四军军部命令与谈话',
            src: '5G3A9862.png'
        },
        { 
            txt: '毛泽东起草的中共中央军委重建新四军军部命令与谈话',
            src: '5G3A9863.png'
        },
        { 
            txt: '毛泽东起草的中共中央军委重建新四军军部命令与谈话',
            src: '5G3A9865.png'
        },
        { 
            txt: '钱之光使用过的国共谈判重要文献',
            src: '5G3A9867.png'
        },
        { 
            txt: '新四军代理军长陈毅就职通电',
            src: '5G3A9870.png'
        },
    ],
    45:[
        { 
            txt: '对敌宣传手册',
            src: '5G3A9873.png'
        },
        { 
            txt: '抗战二队组织浙江游民众抗日歌咏大会节目单',
            src: '5G3A9874.png'
        },
        { 
            txt: '抗宣二队话别临川青年歌咏联谊会节目单',
            src: '5G3A9877.png'
        },
        { 
            txt: '新华日报创刊号',
            src: '5G3A9880.png'
        },
        { 
            txt: '国民政府军事委员会政治部抗敌宣传队铜证章',
            src: '5G3A9885.png'
        },
        { 
            txt: '麦新和孟波主编的大众歌声',
            src: '5G3A9888.png'
        },
        { 
            txt: '麦新和孟波主编的大众歌声',
            src: '5G3A9890.png'
        },
        { 
            txt: '邹韬奋抵抗',
            src: '5G3A9910.png'
        },
        { 
            txt: '邹韬奋生活',
            src: '5G3A9896.png'
        },
        { 
            txt: '邹韬奋生活星期刊',
            src: '5G3A9899.png'
        },
        { 
            txt: '邹韬奋大众生活',
            src: '5G3A9901.png'
        },
        { 
            txt: '邹韬奋抗战',
            src: '5G3A9905.png'
        },
        { 
            txt: '邹韬奋全民抗战',
            src: '5G3A9911.png'
        },
    ],
    46:[
        { 
            txt: '中华全国文艺界抗敌协会会报《抗战文艺》创刊号第1卷第1号',
            src: '5G3A1976.png'
        }
    ],
    47:[
        { 
            txt: '加拿大医药援华会给保卫中国同盟主席宋庆龄的汇款传票',
            src: '5G3A1978.png'
        },
        { 
            txt: '加拿大医药援华会给保卫中国同盟主席宋庆龄的汇款传票',
            src: '5G3A1980.png'
        }
    ],
    48:[
        { 
            txt: '1938年出版的《周恩来邓颖超最近言论》（假本）',
            src: '5G3A1982.png'
        }
    ],
    49:[
        { 
            txt: '延安解放日报社出版《解放日报》第1450号',
            src: '5G3A1983.png'
        }
    ],
    50:[
        { 
            txt: '延安炮兵学校任命菲律宾归侨黄登保',
            src: '221A5024.png'
        },
        { 
            txt: '八路军给救国时报的信',
            src: '221A5026.png'
        },
        { 
            txt: '毛泽东为南洋华侨战地记者通讯团题词',
            src: '221A5021.png'
        },
    ],
    51:[
        { 
            txt: '1937年10月英国记者詹姆斯贝特兰访问毛泽东朱德的谈话记录打字稿',
            src: '5G3A9924.png'
        },
        { 
            txt: '第18集团军总司令朱德为汉斯·米勒出具的服务证明书',
            src: '5G3A9926.png'
        },
        { 
            txt: '中国共产党为太平洋战争的宣言',
            src: '5G3A9930.png'
        },
    ],
    52:[
        { 
            txt: '第18集团军总司令朱德为汉斯·米勒出具的服务证明书',
            src: '221A5083.png'
        },
        { 
            txt: '解放出版出版的中国共产党抗战一般情况的介绍1944年',
            src: '5G3A9939.png'
        }
    ],
    53:[
        { 
            txt: '琼崖华侨联合总会回乡服务团总团长符克烈士使用的短剑护手',
            src: '063A4049.png'
        },
        { 
            txt: '朱德在陕北公学接见华侨学生时为野克作的题词',
            src: '221A5016.png'
        },
        { 
            txt: '泰国归侨野克在陕北公学学习时',
            src: '221A5020.png'
        },
        { 
            txt: '陈嘉庚发给黄仁寿的第二届南洋华侨筹赈祖国难民总会执行委员证书',
            src: '5G3A9946.png'
        }
    ],
    54:[
        { 
            txt: '中国共产党抗日救国十大纲领',
            src: '221A5081.png'
        }
    ],
    55:[
        { 
            txt: '1938年第八路军总指挥部颁给张怀思的退伍证（三级）kjj659',
            src: '221A4729.png'
        },
        { 
            txt: '1939年陆军新编第四军驻赣办事处给王良益回家探亲开具的证明书kjj670',
            src: '221A4724.png'
        },
        { 
            txt: '陕甘宁边区政府发行的伍元、五石',
            src: '221A5013.png'
        },
        { 
            txt: '1938年陆军新编第四军发给江西慈化优待新四军李长栢家属的公函kjj671',
            src: '221A4727.png'
        }
    ],
    56:[
        { 
            txt: '晋冀鲁豫边区政府太岳行署优待抗战军人家属条例',
            src: '5G3A9959.png'
        }
    ],
    57:[
        { 
            txt: '1940年9月20日山西平北县关某因阻止儿媳参加识字班的悔过书kjj727',
            src: '221A4722.png'
        },
        { 
            txt: '初级战时新课本',
            src: '5G3A9962.png'
        },
        { 
            txt: '高小地理',
            src: '5G3A9964.png'
        },
        { 
            txt: '中级算术课本',
            src: '5G3A9967.png'
        },
        { 
            txt: '抗战时期奋勇杀敌救国荣誉纪念章',
            src: '063A3961.png'
        },
        { 
            txt: '冀鲁豫军区颁发的人民功臣奖章（背面贴有文物号）',
            src: '063A3957.png'
        },
        { 
            txt: '晋察冀边区第二中学毕业证',
            src: '5G3A9972.png'
        },
    ],
    58:[
        { 
            txt: '晋察冀边区四分区正定县小韩楼村抗日儿童团用的黄背包',
            src: '063A3992.png'
        },
        { 
            txt: '晋察冀边区四分区正定县小韩楼村抗日儿童团用的毡帽',
            src: '063A3988.png'
        },
        { 
            txt: '晋察冀边区四分区正定县小韩楼村抗日儿童团用的皮带',
            src: '063A3983.png'
        },
        { 
            txt: '晋察冀边区四分区正定县小韩楼村抗日儿童团用的镰刀',
            src: '063A3978.png'
        },
        { 
            txt: '晋察冀边区四分区正定县小韩楼村抗日儿童团用的锄头',
            src: '063A3970.png'
        },
        { 
            txt: '子弟兵的母亲戎冠秀',
            src: '5G3A9980.png'
        },
        { 
            txt: '1945年3月解放蒙阴城战斗中莱芜县独轮车担架队长卓继福',
            src: '5G3A9999.png'
        },
        { 
            txt: '子弟兵的母亲“锦旗NW0026 kjj712',
            src: '221A4789.png'
        },
        { 
            txt: '焦庄户妇女支援前线做的布鞋',
            src: '063A3999.png'
        },
        { 
            txt: '平山县胡家町村长武受成所记之前鞋帐',
            src: '5G3A9988.png'
        },
    ],
    59:[
        { 
            txt: '多种版本的新民主主义论',
            src: '221A5072.png'
        },
        { 
            txt: '多种版本的新民主主义论',
            src: '221A5074.png'
        },
        { 
            txt: '多种版本的新民主主义论',
            src: '221A5076.png'
        },
        { 
            txt: '多种版本的新民主主义论',
            src: '221A5078.png'
        },
        { 
            txt: '多种版本的新民主主义论',
            src: '5G3A0008.png'
        },
    ],
    60:[
        { 
            txt: '1938年1月粟裕在救亡干部学院做的游击战术讲授大纲油印本',
            src: '5G3A0012.png'
        },
        { 
            txt: '刘少奇著抗日游击战争中各种基本政策问题',
            src: '5G3A0017.png'
        },
        { 
            txt: '刘少奇著抗日游击战争中各种基本政策问题',
            src: '5G3A0019.png'
        },
        { 
            txt: '抗日游击战争',
            src: '5G3A0022.png'
        },
        { 
            txt: '朱德著论游击战',
            src: '5G3A0027.png'
        },
    ],
    61:[
        { 
            txt: '多版本论持久战',
            src: '5G3A0031.png'
        },
        { 
            txt: '多版本论持久战',
            src: '5G3A0033.png'
        },
        { 
            txt: '多版本论持久战',
            src: '5G3A0034.png'
        },
        { 
            txt: '多版本论持久战',
            src: '5G3A0040.png'
        },
        { 
            txt: '多版本论持久战',
            src: '5G3A0044.png'
        },
        { 
            txt: '多版本论持久战',
            src: '5G3A0046.png'
        },
    ],
    62:[
        { 
            txt: '有毛泽东“抗日游击战争的战略问题” 《解放》周刊第40期',
            src: '221A5068.png'
        }
    ],
    63:[
        { 
            txt: '毛泽东《关于山地独立自主游击战的策略指示》',
            src: '221A5064.png'
        },
        { 
            txt: '毛泽东《关于山地独立自主游击战的策略指示》',
            src: '221A5065.png'
        },
    ],
    64:[
        { 
            txt: '毛泽东关于华北八路军战略部署的手稿关于整个华北应以游击战为唯一方向的指示',
            src: '5G3A0055.png'
        }
    ],
    65:[
        { 
            txt: '多版本论持久战',
            src: '5G3A0058.png'
        },
        { 
            txt: '多版本论持久战',
            src: '5G3A0062.png'
        },
        { 
            txt: '多版本论持久战',
            src: '5G3A0064.png'
        },
        { 
            txt: '多版本论持久战',
            src: '5G3A0065.png'
        },
        { 
            txt: '多版本论持久战',
            src: '5G3A0067.png'
        },
        { 
            txt: '多版本论持久战',
            src: '5G3A0070.png'
        },
        { 
            txt: '多版本论持久战',
            src: '5G3A0072.png'
        },
    ],
    66:[
        { 
            txt: '黄道著抗日游击战争的实际知识',
            src: '5G3A1211.png'
        },
        { 
            txt: '丁三著广州出版的民众怎么参加游击战',
            src: '5G3A1203.png'
        },
        { 
            txt: '姚承三编著重庆独立出版社印游击战评价',
            src: '5G3A1205.png'
        },
        { 
            txt: '毛泽东著抗日游击战争的战略问题',
            src: '5G3A1190.png'
        },
        { 
            txt: '毛泽东朱德等合著上海生活出版社印行的八路军的战略和战术',
            src: '5G3A1182.png'
        },
        { 
            txt: '60 毛泽东题词手迹坚持游击战争',
            src: '5G3A1195.png'
        },
    ],
    67:[
        { 
            txt: '日军第1军司令官香月清司发布的极密训示油印件',
            src: '221A5058.png'
        },
        { 
            txt: '周静园著抗日游击战术',
            src: '5G3A1234.png'
        },
        { 
            txt: '宇野作冀鲁边区开展游击战争的经验教训和今后工作方针的报告',
            src: '5G3A1227.png'
        },
        { 
            txt: '陈漫远著抗日战争的战术问题 陈漫远著抗日战争的战术问题游击战争的战略问题',
            src: '5G3A1233.png'
        }
    ],
    68:[
        { 
            txt: '民国33年（1944）大众出版社出版的《敌后抗日根据地介绍》',
            src: '5G3A1986.png'
        },
        { 
            txt: '新华书店印行的《敌后抗日根据地介绍》',
            src: '221A5057.png'
        },
        { 
            txt: '胶东联合社编印《敌后抗日根据地介绍》',
            src: '221A5055.png'
        },
        { 
            txt: '旅顺民众书店翻印《敌后抗日根据地介绍》',
            src: '5G3A1988.png'
        },
        { 
            txt: '新长城社编印扶余解放社翻印《敌后抗日民主根据地介绍》',
            src: '5G3A1990.png'
        }
    ],
    69:[
        { 
            txt: '1938年解放社出版的论新阶段',
            src: '5G3A1287.png'
        }
    ],
    70:[
        { 
            txt: '任弼时在共产国际时使用的皮箱',
            src: '063A4058.png'
        },
        { 
            txt: '香港出版的扩大的中共六届六中全会决议以及公开电',
            src: '5G3A0094.png'
        },
    ],
    71:[
        { 
            txt: '齐礼编辑解放社出版《陕甘宁边区实录》',
            src: '221A5051.png'
        }
    ],
    72:[
        { 
            txt: '1939年聂荣臻著八路军军政杂志社出版的《抗日模范根据地晋察冀边区》',
            src: '5G3A1995.png'
        }
    ],
    73:[
        { 
            txt: '陕甘宁边区政府一年工作总结》假本',
            src: '5G3A1992.png'
        }
    ],
    74:[
        { 
            txt: '抗日民主根据地晋察冀边区详图',
            src: '221A5044.png'
        }
    ],
    75:[
        { 
            txt: '长乐村战斗中缴获的日军绑腿',
            src: '5G3A2367.png'
        },
        { 
            txt: '晋察冀军区第4分区司令员兼政委邓华使用的马褡子',
            src: '5G3A2357.png'
        },
        { 
            txt: '冀晋军区政委兼政治部主任王平使用的蚊帐',
            src: '5G3A2362.png'
        },
        { 
            txt: '聂荣臻将军怎样创造晋察冀解放区的群众周刊',
            src: '5G3A0107.png'
        },
        { 
            txt: '聂荣臻将军怎样创造晋察冀解放区的群众周刊',
            src: '5G3A0108.png'
        },
    ],
    76:[
        { 
            txt: '1938年9月1日原新四军4师10旅政治部主任高农斧写给其母亲的信',
            src: '221A5040.png'
        },
        { 
            txt: '1938年9月1日原新四军4师10旅政治部主任高农斧写给其母亲的信',
            src: '221A5043.png'
        },
    ],
    77:[
        { 
            txt: '贺龙佩带的“八路”臂章JF004247',
            src: '221A4715.png'
        },
        { 
            txt: '贺龙佩带的胸章JF004248',
            src: '221A4710.png'
        },
        { 
            txt: '沙汀著随军散记',
            src: '5G3A0113.png'
        },
        { 
            txt: '八路军第120师战斗报记者铁冲用过的毛毯',
            src: '5G3A0116.png'
        },
    ],
    78:[
        { 
            txt: '1942年李少言创作的版画《重建》',
            src: '221A5037.png'
        },
        { 
            txt: '1941年晋西北美术工厂厂长李少言亲手制作和使用的工作——刨子',
            src: '063A4087.png'
        },
        { 
            txt: '1941年晋西北美术工厂厂长李少言亲手制作和使用的工作——锤子',
            src: '063A4099.png'
        },
        { 
            txt: '1941年2月晋西美术工厂厂长李少言使用的装刻刀的木盒',
            src: '063A4115.png'
        },
        { 
            txt: '1941年晋西北美术工厂厂长李少言亲手制作和使用的工作——刻刀',
            src: '5G3A2378.png'
        },
        { 
            txt: '1941年晋西北美术工厂厂长李少言亲手制作和使用的工作——小锤子 ',
            src: '221A4710.png'
        },
        { 
            txt: '1941年晋西北美术工厂厂长李少言亲手制作和使用的工作——凿子',
            src: '063A4107.png'
        },
        { 
            txt: '1941年晋西北美术工厂厂长李少言亲手制作和使用的工作——刻刀',
            src: '063A4077.png'
        },
    ],
    79:[
        { 
            txt: '师政治部印“朱德青年队员王光华证书”',
            src: '5G3A1996.png'
        },
        { 
            txt: '129师司令部译编《日寇特务工作之一斑》',
            src: '5G3A2000.png'
        },
    ],
    80:[
        { 
            txt: '抗日根据地晋冀鲁豫边区地图',
            src: '221A5032.png'
        }
    ],
    81:[
        { 
            txt: '冀鲁豫军区直属队政治处主任刘洪玉使用的毛毯',
            src: '5G3A0123.png'
        },
        { 
            txt: '山东新华书店出版的血战在晋冀鲁豫边区',
            src: '5G3A0126.png'
        },
        { 
            txt: '郭洪安晋冀鲁豫边区太岳四专区医生证章',
            src: '5G3A2580.png'
        },
        { 
            txt: '晋冀鲁豫军工总会职工会员证章',
            src: '5G3A2576.png'
        },
        { 
            txt: '晋冀鲁豫军区陆军中学毕业章',
            src: '5G3A0132.png'
        },
    ],
    82:[
        { 
            txt: '冀鲁豫军区8分区7团参谋长王正南与同参加抗战的11位家人合影',
            src: '221A5011.png'
        }
    ],
    83:[
        { 
            txt: '决死队四周年纪念章',
            src: '5G3A2573.png'
        },
        { 
            txt: '山西青年抗敌决死队总队王聚昌济生药房救国捐款各壹拾元大洋收据',
            src: '5G3A0137.png'
        },
        { 
            txt: '1938年决死队在沁县韩洪战役中缴获的望远镜',
            src: '5G3A2417.png'
        },
        { 
            txt: '山西新军（决死队）军帽',
            src: '5G3A2426.png'
        },
        { 
            txt: '山东泰西地区抗日游击队战士田笑海缴获的日军毛毯',
            src: '5G3A2396.png'
        },
        { 
            txt: '高克普编上海南华出版社发行的第八路军在山西',
            src: '5G3A0144.png'
        },
        { 
            txt: '山东军区情报处《情报汇编》第九集',
            src: '5G3A2008.png'
        },
        { 
            txt: '山东滨海军区司令部情况总结',
            src: '5G3A2002.png'
        },
        { 
            txt: '胶东军区司令部编1945年上半年胶东情况总结报告',
            src: '5G3A2006.png'
        },
        { 
            txt: '陆军第18集团军山东纵队胶东第3支队政治部发给沙夫的通行护照',
            src: '221A5009.png'
        },
    ],
    84:[
        { 
            txt: '程子华著战线社出版《冀中平原上的民兵斗争》',
            src: '5G3A2012.png'
        }
    ],
    85:[
        { 
            txt: '挺进报社出版肖克关于《创造新的抗战根据地问题》一文油印本',
            src: '5G3A2017.png'
        }
    ],
    86:[
        { 
            txt: '1938年8月27日刊载有“冀东伪军全瓦解” 相关报道的《新华日报》第228号',
            src: '5G3A0155.png'
        },
        { 
            txt: '1938年8月27日刊载有“冀东伪军全瓦解” 相关报道的《新华日报》第228号',
            src: '5G3A2015.png'
        },
    ],
    87:[
        { 
            txt: '冀热辽军区政治部主任李中权装文件的马褡子 相关报道的《新华日报》第228号',
            src: '5G3A2568.png'
        },
        { 
            txt: '中共冀热边区特委出版的党内刊物坚持第11期 相关报道的《新华日报》第228号',
            src: '5G3A0159.png'
        },
        { 
            txt: '冀热察挺进军宣传部长罗立斌使用的笔记本',
            src: '5G3A0163.png'
        },
    ],
    88:[
        { 
            txt: '平北军区司令员段苏权给白吴才同志的信',
            src: '221A5001.png'
        },
        { 
            txt: '平北军区司令员段苏权给白吴才同志的信',
            src: '221A5003.png'
        },
        { 
            txt: '平北军区司令员段苏权给白吴才同志的信',
            src: '221A5004.png'
        },
    ],
    89:[
        { 
            txt: '《新四军第五师印八路军新四军的抗战成绩和敌后抗日根据地的概况》假本',
            src: '5G3A2019.png'
        },
        { 
            txt: '新四军3师8旅24团政治处宣传股股长黄洪波使用的箱子',
            src: '5G3A2435.png'
        },
        { 
            txt: '新四军文艺战士晓河用过的指南针',
            src: '5G3A2408.png'
        },
        { 
            txt: '董超同志在新四军使用的铜质军用怀表式指南针',
            src: '5G3A2399.png'
        },
        { 
            txt: '新四军参谋长赖传珠使用的马褡子',
            src: '5G3A2564.png'
        },
        { 
            txt: '新四军参谋长赖传珠使用的缴获日军雨布',
            src: '5G3A2555.png'
        },
        { 
            txt: '新四军第2师6旅淮西独立团1连3排战士蒋学启使用的军号',
            src: '5G3A2391.png'
        },
        { 
            txt: '新四军第5师地图1938年',
            src: '5G3A0175.png'
        },
        { 
            txt: '对今后南方各游击区指示及其活动方针》',
            src: '221A4998.png'
        },
        { 
            txt: '对今后南方各游击区指示及其活动方针》',
            src: '221A5000.png'
        },
        { 
            txt: '新四军任天石烈士使用的手电筒',
            src: '5G3A0193.png'
        },
        { 
            txt: '新四军江淮大学学员贾顺的临别赠言纪念册',
            src: '5G3A0201.png'
        },
        { 
            txt: '中共苏中区党委宣传部翻印的关于目前形势与我们的方针任务1945年',
            src: '221A5009.png'
        },
        { 
            txt: '新四军第3师军工部研究室副主任金岗使用过的笔记本',
            src: '5G3A0205.png'
        },
        { 
            txt: '新四军卫生部化验训练班学员徐欢的笔记本',
            src: '5G3A0207.png'
        },
        { 
            txt: '刊有袁国平著论江南伪军工作的群众周刊1939',
            src: '5G3A0212.png'
        },
        { 
            txt: '刊有朱克靖庆祝新四军成立六周年纪念会以与感想的江潮报1943',
            src: '5G3A0219.png'
        },
        { 
            txt: '中央关于建立与巩固华中抗日根据地的指示',
            src: '221A4984.png'
        },
        { 
            txt: '中共盐阜报',
            src: '5G3A0230.png'
        },
    ],
    90:[
        { 
            txt: '《广东人民抗日游击队东江纵队成立宣言》',
            src: '221A4988.png'
        }
    ],
    91:[
        { 
            txt: '1945年《广东人民抗日游击队东江纵队布告》纵字第5号',
            src: '5G3A2021.png'
        }
    ],
    92:[
        { 
            txt: '琼崖纵队授予罗金荣“抗日英雄”锦旗',
            src: '221A4978.png'
        }
    ],
    93:[
        { 
            txt: '中国共产党广东省委为挽救广东全面沦陷危机坚持对敌斗争宣言',
            src: '5G3A0239.png'
        }
    ],
    94:[
        { 
            txt: '广东青年抗日先锋队台上县独立第1支队出版的先锋创刊号',
            src: '5G3A0242.png'
        },
        { 
            txt: '广东人民游击队珠江纵队成立宣言',
            src: '5G3A0249.png'
        },
    ],
    95:[
        { 
            txt: '林伯渠《边区三三制的经验及其应该纠正的偏向》',
            src: '221A4970.png'
        },
        { 
            txt: '晋察冀边区第一届参议会通过的晋察冀边区施政纲领',
            src: '5G3A0259.png'
        },
    ],
    96:[
        { 
            txt: '中共边区中央局印《陕甘宁边区施政纲领》',
            src: '221A4973.png'
        }
    ],
    97:[
        { 
            txt: '上西壶关县政府关于简政交接中粮款的亏欠清理办法给公安局的通令',
            src: '5G3A0263.png'
        },
        { 
            txt: '晋察冀边区行政委员会颁布晋察冀边区点滴社刊印的现行法律汇编第一编',
            src: '5G3A0265.png'
        },
        { 
            txt: '晋冀鲁豫边区政府编韬奋书店发行的晋冀鲁豫边区法令汇编',
            src: '5G3A0269.png'
        },
    ],
    98:[
        { 
            txt: '毛泽东关于精兵简政问题给谢觉哉陈正人的信1942',
            src: '5G3A0273.png'
        }
    ],
    99:[
        { 
            txt: '徐灵创作的木刻《我选王二禾》',
            src: '221A4966.png'
        },
        { 
            txt: '徐灵创作的油印宣传品《选举不小心的害处》kj903',
            src: '221A4968.png'
        },
        { 
            txt: '陕甘宁边区选举委员会编印选举文件第一辑',
            src: '5G3A0279.png'
        },
    ],
    100:[
        { 
            txt: '陕甘宁边区简政总结',
            src: '5G3A2024.png'
        }
    ],
    101:[
        { 
            txt: '晋察冀鲁豫边区政府颁布的减租减息布告',
            src: '221A5209.png'
        }
    ],
    102:[
        { 
            txt: 'kj000242铁雷',
            src: '063A4151.png'
        },
        { 
            txt: 'kj000240铁雷',
            src: '063A4126.png'
        },
        { 
            txt: '铁雷',
            src: '5G3A0291.png'
        },
        { 
            txt: '铁雷',
            src: '5G3A0292.png'
        },
        { 
            txt: '瓷雷',
            src: '063A4200.png'
        },
        { 
            txt: '瓷雷',
            src: '063A4197.png'
        },
        { 
            txt: '瓷雷',
            src: '5G3A0284.png'
        },
        { 
            txt: '瓷雷',
            src: '5G3A0286.png'
        },
        { 
            txt: '徐灵素描《地雷战》kj2920',
            src: '221A4865.png'
        },
        { 
            txt: '徐灵素描《研究摆雷》kj2917',
            src: '221A4864.png'
        },
        { 
            txt: '徐灵素描《李勇大摆地雷阵痛击扫荡日军》kj2912',
            src: '221A4866.png'
        },
        { 
            txt: '复制件：铁道游击队队员掩护身份使用的算盘JF001062',
            src: '063A4187.png'
        },
        { 
            txt: '石雷',
            src: '5G3A0300.png'
        },
        { 
            txt: '地雷埋藏法图解',
            src: '5G3A0302.png'
        },
        { 
            txt: '地雷',
            src: '5G3A0301.png'
        },
        { 
            txt: '土造地雷的构造及使用法',
            src: '5G3A0303.png'
        },
        { 
            txt: '抗战时期的“太行区群英大会”纪念章',
            src: '063A4202.png'
        },
        { 
            txt: '雁翎队使用过的鱼叉',
            src: '063A4118.png'
        },
        { 
            txt: '冀中白洋淀雁翎队战时用过的冰床',
            src: '5G3A0308.png'
        },
        { 
            txt: '冀中白洋淀雁翎队战时用过的长枪',
            src: '5G3A0313.png'
        },
    ],
    103:[
        { 
            txt: '新四军《前哨画报》刊登的北什沟战斗片断连环画',
            src: '5G3A2128.png'
        }
    ],
    104:[
        { 
            txt: '1942年3月6日原新四军3师7旅19团2营4连指导员李云鹏写给家人的平安信信封kj015090',
            src: '221A4872.png'
        },
        { 
            txt: '原新四军3师7旅19团2营4连指导员李云鹏的照片kj15091',
            src: '221A4871.png'
        },
    ],
    105:[
        { 
            txt: '民国34年（1945）2月17日新四军阜东县独立团团长郝济民写给父母的信kj140076',
            src: '221A4875.png'
        },
        { 
            txt: '民国34年（1945）2月17日新四军阜东县独立团团长郝济民写给父母的信kj140076',
            src: '221A4876.png'
        },
        { 
            txt: '民国34年（1945）2月17日新四军阜东县独立团团长郝济民写给父母的信kj140076',
            src: '221A4878.png'
        },
        { 
            txt: '民国34年（1945）2月17日新四军阜东县独立团团长郝济民写给父母的信kj140076',
            src: '221A4879.png'
        },
        { 
            txt: '民国34年（1945）2月17日新四军阜东县独立团团长郝济民写给父母的信kj140076',
            src: '221A4880.png'
        },
    ],
    106:[
        { 
            txt: '近战战术',
            src: '5G3A0326.png'
        },
        { 
            txt: '三年抗战与八路军',
            src: '5G3A0330.png'
        },
        { 
            txt: '中国共产党与抗战军事',
            src: '5G3A0336.png'
        },
        { 
            txt: '八年抗战中的八路军与新四军',
            src: '5G3A0341.png'
        },
        { 
            txt: '八路军抗战的经验教训',
            src: '5G3A0348.png'
        },
    ],
    107:[
        { 
            txt: '罗应怀赠送给许少英的皮包',
            src: '063A4178.png'
        }
    ],
    108:[
        { 
            txt: '1942年反扫荡时受伤的160名一二九师病员登记表（其中一页）kjj728',
            src: '221A4696.png'
        }
    ],
    109:[
        { 
            txt: '战斗画刊（车桥战斗特刊第四号）kf52',
            src: '221A4869.png'
        }
    ],
    110:[
        { 
            txt: '《军区指令各部队学习狼牙山五壮士》第三版——《晋察冀日报》第744号kj7184',
            src: '221A4892.png'
        }
    ],
    111:[
        { 
            txt: '冀南枣强敌工部流常地下情报站情报员张精明用的照相机',
            src: '063A4155.png'
        }
    ],
    112:[
        { 
            txt: '中共中央军委对华南根据地工作的指示',
            src: '5G3A0358.png'
        }
    ],
    113:[
        { 
            txt: '左权在山西武乡砖壁村八路军总部用过的锥子',
            src: '5G3A0389.png'
        }
    ],
    114:[
        { 
            txt: '张友渔著生活书店印行的从防御到反攻',
            src: '5G3A0369.png'
        }
    ],
    115:[
        { 
            txt: '彭德怀著坚持平原游击战争的几个具体问题',
            src: '5G3A0373.png'
        }
    ],
    116:[
        { 
            txt: '从防御到反攻',
            src: '5G3A0391.png'
        }
    ],
    117:[
        { 
            txt: '坚持平原游击战争',
            src: '5G3A0394.png'
        }
    ],
    118:[
        { 
            txt: '百团大战奖章JF003299',
            src: '063A4161.png'
        },
        { 
            txt: '彭德怀奖励八路军供给部部长周玉成百团大战缴获的日军手表',
            src: '063A4158.png'
        },
        { 
            txt: '百团大战中缴获的日本中队长的瑞宝勋章JB045511',
            src: '063A4171.png'
        },
        { 
            txt: '百团大战中太岳纵队第38团编制的烈士名册',
            src: '5G3A0402.png'
        },
    ],
    119:[
        { 
            txt: '彦涵作百团大战中关家垴战斗亲临前线指挥作战彭德怀司令木刻画',
            src: '221A5206.png'
        }
    ],
    120:[
        { 
            txt: '关家垴战斗中缴获日军头盔',
            src: '063A4184.png'
        },
        { 
            txt: '蓟县白草洼战斗中八路军缴获日本关东军武岛骑兵中队公文包',
            src: '063A4176.png'
        },
        { 
            txt: '八路军120师359旅7团3营连长刘光远在五台山反扫荡战斗中用过的弹夹',
            src: '063A4164.png'
        },
        { 
            txt: '关于百团大战双峰战斗的日军笔记',
            src: '5G3A0416.png'
        },
        { 
            txt: '八路军第120师战斗报编辑文路手写的战时记录本',
            src: '5G3A0424.png'
        },
    ],
    121:[
        { 
            txt: '八路军第120师独1旅编写的齐会战斗总结',
            src: '5G3A0431.png'
        },
        { 
            txt: '八路军总司令部政治部印发的告日本士兵书日文宣传单',
            src: '221A4982.png'
        },
    ],
    122:[
        { 
            txt: '四十天反扫荡战役的伟大胜利',
            src: '221A4902.png'
        },
        { 
            txt: '徐灵设计的油印宣传品《晋察冀一分区政治部黄土岭战斗捷报》',
            src: '221A4903.png'
        },
        { 
            txt: '徐灵设计晋察冀一分区政治部的《三岔口黄土岭大捷》2935阿部中将打死在黄土岭',
            src: '221A4905.png'
        },
        { 
            txt: '徐灵设计的油印日文宣传品《阿部规秀被八路军击毙》kj2891',
            src: '221A4904.png'
        },
    ],
    123:[
        { 
            txt: '八路军总司令部政治部布告',
            src: '221A4980.png'
        }
    ],
    124:[
        { 
            txt: '毛泽东在中国延安女子大学成立讲话稿油印件（部分）（18页一部分）',
            src: '5G3A2098.png'
        }
    ],
    125:[
        { 
            txt: '1940年八路军袁英杰（元平）华北联合大学毕业证（社字第183号）',
            src: '5G3A2103.png'
        }
    ],
    126:[
        { 
            txt: '抗战时期牛文创作的毛泽东头像版画kj16354',
            src: '221A4910.png'
        },
        { 
            txt: '抗战时期牛文创作的毛泽东招手像版画kj16355',
            src: '221A4907.png'
        },
        { 
            txt: '1942年牛文创作的版画《学文化》（套色）kj16353',
            src: '221A4911.png'
        },
        { 
            txt: '1941年牛文创作的版画《去延安》kj16351',
            src: '221A4914.png'
        },
        { 
            txt: '1941年牛文创作的版画《暖炕头》kj16352',
            src: '221A4913.png'
        },
    ],
    127:[
        { 
            txt: '1938年在武汉冼星海作曲陈铭枢作词的抗日歌曲《民族精神》手稿kj15992',
            src: '221A4916.png'
        },
        { 
            txt: '1938年在武汉冼星海作曲陈铭枢作词的抗日歌曲《民族精神》手稿kj15992',
            src: '221A4918.png'
        }
    ],
    128:[
        { 
            txt: '159 彭德怀著争取持久抗战胜利的几个先决问题',
            src: '5G3A0455.png'
        },
        { 
            txt: '159 彭德怀著争取持久抗战胜利的几个先决问题',
            src: '5G3A0459.png'
        },
        { 
            txt: '160 刘少奇著坚持华北抗战中的武装部队',
            src: '5G3A0463.png'
        },
        { 
            txt: '朱德著华北抗战的总结油印版',
            src: '5G3A0468.png'
        },
    ],
    129:[
        { 
            txt: '任光创作的抗战革命歌曲',
            src: '5G3A0471.png'
        },
        { 
            txt: '新四军第2师抗敌剧团政治指导员晓河创作的歌曲进击歌1941',
            src: '5G3A0476.png'
        },
        { 
            txt: '新四军第2师抗敌剧团政治指导员晓河创作的歌曲战士之歌1942',
            src: '5G3A0478.png'
        },
        { 
            txt: '1940年战线剧社出版的三幕歌剧从军曲1940',
            src: '5G3A0483.png'
        },
        { 
            txt: '西北战地服务团歌咏队印洛汀使用过的四部合唱歌本1939',
            src: '5G3A0485.png'
        },
        { 
            txt: '冀中军区第7军分区前进剧社社员陈洁使用过的口琴',
            src: '5G3A0488.png'
        },
        { 
            txt: '邓康作词张非作曲的八路军和老百姓歌曲乐谱1942',
            src: '5G3A0491.png'
        },
    ],
    130:[
        { 
            txt: '《抗大五周年纪念大会特辑》（附何长工签名）',
            src: '5G3A2109.png'
        }
    ],
    131:[
        { 
            txt: '1942年4月27日向宗圣写给哥哥（向宗颖）的信',
            src: '221A4929.png'
        },
        { 
            txt: '1942年4月27日向宗圣写给哥哥（向宗颖）的信',
            src: '221A4930.png'
        },
        { 
            txt: '1942年4月27日向宗圣写给哥哥（向宗颖）的信',
            src: '221A4931.png'
        },
        { 
            txt: '1942年4月27日向宗圣写给哥哥（向宗颖）的信',
            src: '221A4932.png'
        },
        { 
            txt: '1942年4月27日向宗圣写给哥哥（向宗颖）的信',
            src: '221A4933.png'
        },
        { 
            txt: '1942年4月27日向宗圣写给哥哥（向宗颖）的信',
            src: '221A4934.png'
        },
        { 
            txt: '1942年4月27日向宗圣写给哥哥（向宗颖）的信',
            src: '221A4935.png'
        },
        { 
            txt: '1942年4月27日向宗圣写给哥哥（向宗颖）的信',
            src: '221A4936.png'
        },
        { 
            txt: '1942年4月27日向宗圣写给哥哥（向宗颖）的信',
            src: '221A4937.png'
        },
        { 
            txt: '1942年4月27日向宗圣写给哥哥（向宗颖）的信',
            src: '221A4938.png'
        },

        { 
            txt: '1938年10月向宗圣到延安后写给父亲的家信（含信封）',
            src: '221A4922.png'
        },
        { 
            txt: '1938年10月向宗圣到延安后写给父亲的家信（含信封）',
            src: '221A4927.png'
        },
        { 
            txt: '1938年10月26日向宗圣写给父亲（向兴斌）哥哥（向宗正）的信（含信封）',
            src: '221A4939.png'
        },
        { 
            txt: '1938年10月26日向宗圣写给父亲（向兴斌）哥哥（向宗正）的信（含信封）',
            src: '221A4942.png'
        },
        { 
            txt: '1938年6月王孝慈（向宗仁）写给弟弟向蕴生的家信信封',
            src: '221A4919.png'
        },
    ],
    132:[
        { 
            txt: '抗大文工团团长牛克伦使用过的笔记本',
            src: '221A4699.png'
        }
    ],
    133:[
        { 
            txt: '《抗大动态》（书内夹有文物号纸条）',
            src: '5G3A2097.png'
        },
        { 
            txt: '中国人民抗日军政大学总校训练部编辑印刷发行左权著《埋伏与袭击》',
            src: '5G3A2086.png'
        },
        { 
            txt: '抗大教职员证章（黄底）',
            src: '5G3A2072.png'
        },
        { 
            txt: '抗大证章',
            src: '5G3A0537.png'
        },
        { 
            txt: '八路军战士马皆德在延安抗大学习期间写给母亲的信',
            src: '5G3A0522.png'
        },
        { 
            txt: '抗大毕业证书1940',
            src: '5G3A0531.png'
        },
        { 
            txt: '抗大创校五周年纪念奖章',
            src: '5G3A0543.png'
        },
    ],
    134:[
        { 
            txt: '晋察冀军区工业部技师高霭亭使用的缴获日军弹药箱',
            src: '5G3A2489.png'
        },
        { 
            txt: '习仲勋为刘鼎题词“兵工泰斗 统战功臣”',
            src: '221A5210.png'
        },
        { 
            txt: '晋察冀军区工业部技术研究室技术人员张方穿过的上衣',
            src: '5G3A2532.png'
        },
        { 
            txt: '晋察冀军区工业部六连化铁炉用的炉条',
            src: '5G3A2439.png'
        },
        { 
            txt: '晋察冀军区工业部技术员张奎元使用的游标卡尺',
            src: '5G3A2436.png'
        },
        { 
            txt: '晋察冀军区工业部技术员韦彬使用的铜勺',
            src: '5G3A2517.png'
        },
        { 
            txt: '八路军第129师军工部兵工厂战士樊玉成保存的翻砂工具',
            src: '5G3A2520.png'
        },
        { 
            txt: '八路军第129师军工部兵工厂战士樊玉成保存的翻砂工具',
            src: '5G3A2507.png'
        },
        { 
            txt: '八路军总部军工部部长刘鼎使用的计算尺',
            src: '5G3A2471.png'
        },
        { 
            txt: '八路军总部军工部部长刘鼎使用的德国addiator双面计算器',
            src: '5G3A2461.png'
        },
        { 
            txt: '八路军总部军工部部长刘鼎使用的转弯螺丝刀',
            src: '5G3A2460.png'
        },
        { 
            txt: '晋察冀军区管理处王韬的军工笔记',
            src: '5G3A0556.png'
        },
        { 
            txt: '晋察冀军区军工部陈德寿制作炸药的笔记',
            src: '5G3A0563.png'
        },
        { 
            txt: '晋察冀军区工业部使用的化学参考书',
            src: '5G3A0570.png'
        },
        { 
            txt: '晋察冀军区工业部六连生产的手榴弹壳',
            src: '5G3A2451.png'
        },
        { 
            txt: '晋察冀军区工业部六连生产的手榴弹壳',
            src: '5G3A2447.png'
        },
        { 
            txt: '晋察冀军区工业部六连生产的手榴弹壳',
            src: '5G3A2443.png'
        },
        { 
            txt: '晋察冀边区工业部颁发的1943年纪念章',
            src: '5G3A0579.png'
        },
        { 
            txt: '晋察冀军区工业部颁发给张运恒的铁军前卫奖章',
            src: '5G3A0581.png'
        },
        { 
            txt: '晋察冀军区工业部化学厂王之任使用的包袱皮',
            src: '5G3A0588.png'
        },
    ],
    135:[
        { 
            txt: '晋察冀边区部队使用的小米证（粮票',
            src: '221A4707.png'
        }
    ],
    136:[
        { 
            txt: '抗战时期张德贵穿过的军棉大衣',
            src: '5G3A2546.png'
        },
        { 
            txt: '苏中行政公署领粮证伍佰斤',
            src: '5G3A2553.png'
        },
        { 
            txt: '晋冀鲁豫边区冀南银行证章',
            src: '5G3A2411.png'
        },
    ],
    137:[
        { 
            txt: '1943年“晋冀鲁豫边区禁止敌伪钞票暂行办法”手抄页',
            src: '5G3A2035.png'
        }
    ],
    138:[
        { 
            txt: '庄老太太（吴珠梅）1944年绥德专区合股土纱第二届展览会甲等奖状',
            src: '5G3A2032.png'
        }
    ],
    139:[
        { 
            txt: '晋察冀边区行政委员会救国公债壹圆',
            src: '5G3A2065.png'
        }
    ],
    140:[
        { 
            txt: '晋西北临时参议会颁发晋西北巩固农币公债券贰圆',
            src: '5G3A2068.png'
        }
    ],
    141:[
        { 
            txt: '晋察冀边区第11专区粮票小米拾贰两木柴壹斤半',
            src: '5G3A2044.png'
        },
        { 
            txt: '晋察冀边区第11专区粮票小米壹斤半',
            src: '5G3A2053.png'
        },
        { 
            txt: '晋察冀边区第11专区粮票小米壹斤半、木柴叁斤',
            src: '5G3A2050.png'
        },
        { 
            txt: '晋察冀边区公粮票小米拾贰两',
            src: '5G3A2061.png'
        },
        { 
            txt: '晋察冀边区第11专区粮柴票食用粮八斤二两折菜粮十两公柴十二斤半',
            src: '5G3A2057.png'
        },
    ],
    142:[
        { 
            txt: '八路军关于增加生产和厉行节约的命令',
            src: '5G3A0605.png'
        }
    ],
    143:[
        { 
            txt: '朱德游南泥湾时的游记',
            src: '5G3A2031.png'
        }
    ],
    144:[
        { 
            txt: '陕甘宁边区贸易公司的商业流通券',
            src: '5G3A0614.png'
        },
        { 
            txt: '陕甘宁边区贸易公司的商业流通券',
            src: '5G3A0619.png'
        },
        { 
            txt: '陕甘宁边区贸易公司的商业流通券',
            src: '5G3A0622.png'
        },
        { 
            txt: '陕甘宁边区贸易公司的商业流通券',
            src: '5G3A0625.png'
        },
        { 
            txt: '陕甘宁边区贸易公司的商业流通券',
            src: '5G3A0628.png'
        },
        { 
            txt: '陕甘宁边区贸易公司的商业流通券',
            src: '5G3A0630.png'
        },
        { 
            txt: '陕甘宁边区贸易公司的商业流通券',
            src: '5G3A0634.png'
        },
        { 
            txt: '陕甘宁边区贸易公司的商业流通券',
            src: '5G3A0636.png'
        },
        { 
            txt: '陕甘宁边区贸易公司的商业流通券',
            src: '5G3A0638.png'
        },
        { 
            txt: '陕甘宁边区贸易公司的商业流通券',
            src: '5G3A0639.png'
        },
    ],
    145:[
        { 
            txt: '华中银行江淮银行豫鄂边区建设银行盐阜银行江淮银行',
            src: '5G3A0644.png'
        },
        { 
            txt: '华中银行江淮银行豫鄂边区建设银行盐阜银行江淮银行',
            src: '5G3A0647.png'
        },
        { 
            txt: '华中银行江淮银行豫鄂边区建设银行盐阜银行江淮银行',
            src: '5G3A0649.png'
        },
        { 
            txt: '华中银行江淮银行豫鄂边区建设银行盐阜银行江淮银行',
            src: '5G3A0653.png'
        },
        { 
            txt: '华中银行江淮银行豫鄂边区建设银行盐阜银行江淮银行',
            src: '5G3A0736.png'
        },
        { 
            txt: '西北银行',
            src: '5G3A0656.png'
        },
        { 
            txt: '西北银行',
            src: '5G3A0661.png'
        },
        { 
            txt: '西北银行',
            src: '5G3A0662.png'
        },
        { 
            txt: '西北银行',
            src: '5G3A0747.png'
        },
        { 
            txt: '北海银行',
            src: '5G3A0668.png'
        },
        { 
            txt: '北海银行',
            src: '5G3A0672.png'
        },
        { 
            txt: '北海银行',
            src: '5G3A0675.png'
        },
        { 
            txt: '北海银行',
            src: '5G3A0677.png'
        },
        { 
            txt: '北海银行',
            src: '5G3A0753.png'
        },
        { 
            txt: '冀南银行',
            src: '5G3A0686.png'
        },
        { 
            txt: '冀南银行',
            src: '5G3A0691.png'
        },
        { 
            txt: '冀南银行',
            src: '5G3A0697.png'
        },
        { 
            txt: '冀南银行',
            src: '5G3A0757.png'
        },
        { 
            txt: '晋察冀边区银行',
            src: '5G3A0700.png'
        },
        { 
            txt: '晋察冀边区银行',
            src: '5G3A0705.png'
        },
        { 
            txt: '晋察冀边区银行',
            src: '5G3A0710.png'
        },
        { 
            txt: '晋察冀边区银行',
            src: '5G3A0761.png'
        },
    ],
    146:[
        { 
            txt: '自己动手丰衣足食',
            src: '221A5213.png'
        },
        { 
            txt: '太岳根据地大生产运动中生产的毛毯',
            src: '5G3A2381.png'
        }
    ],
    147:[
        { 
            txt: '八路军129骑兵团师缴获日军皮靴',
            src: '063A4238.png'
        },
        { 
            txt: '新四军第四师缴获的伪军的皮带',
            src: '063A4225.png'
        },
        { 
            txt: '新四军战士韦满春在1945年大反攻时缴获的望远镜',
            src: '5G3A0775.png'
        },
        { 
            txt: '新四军战士韦满春在1945年大反攻时缴获的望远镜',
            src: '5G3A0778.png'
        },
        { 
            txt: '八路军第129师骑兵团团长王振祥缴获并使用的大衣',
            src: '5G3A0783.png'
        },
        { 
            txt: '八路军第129师骑兵团团长王振祥缴获并使用的毛裤',
            src: '5G3A0785.png'
        },
        { 
            txt: '八路军第129师骑兵团团长王振祥缴获并使用的绑腿',
            src: '5G3A0794.png'
        },
        { 
            txt: '八路军第129师骑兵团团长王振祥缴获并使用的绑腿',
            src: '5G3A0795.png'
        },
    ],
    148:[
        { 
            txt: '山东军区教导团第3大队副大队长朱家鼎保存的缴获日军防寒帽',
            src: '063A4222.png'
        },
        { 
            txt: '山东鲁中军区第2军分区政治部宣传队长郑明使用的缴获的日本“朝日”牌雨靴',
            src: '063A4227.png'
        },
        { 
            txt: '冀中军区第10军分区政治部除奸科科长胡文庄缴获的日军雨披',
            src: '5G3A0803.png'
        },
        { 
            txt: '抗战时期遗留在河北保定市蠡县的侵华日军钢盔',
            src: '063A4219.png'
        }
    ],
    149:[
        { 
            txt: '1944年6月东江纵队虎门情报站情报人员欧培缴获的日军望远镜',
            src: '063A4236.png'
        },
        { 
            txt: '冀中十分区在雄县“神营战斗”中缴获日军的饭盒',
            src: '063A4213.png'
        },
        { 
            txt: '四军第8纵队受降高邮谈判时缴获日军军用铁锹',
            src: '063A4231.png'
        },
        { 
            txt: '晋察冀军区青年支队政委蔡长元日军明信片',
            src: '5G3A0824.png'
        },
        { 
            txt: '刘欣保存的1945年日军毛毯',
            src: '5G3A0831.png'
        },
        { 
            txt: '八路军第四纵队司令员宋时轮多用途木杆',
            src: '5G3A0836.png'
        },
        { 
            txt: '原八路军一二九师七六九团特务连排长王发义缴获的日军望远镜',
            src: '063A4242.png'
        },
        { 
            txt: '晋察冀军区第2军分区第19团战士张隆槐缴获的日军水壶',
            src: '063A4217.png'
        },
        { 
            txt: '八路军修械所造手枪',
            src: '5G3A0843.png'
        },
        { 
            txt: '三八式6.5mm步枪',
            src: '063A4218.png'
        },
        { 
            txt: '249 1924年式7.9毫米非自动毛瑟枪（中正式）',
            src: '063A4220.png'
        },
    ],
    150:[
        { 
            txt: '晋察冀军区第三军分区司令员孙毅保存使用缴获日军防寒披风',
            src: '063A4245.png'
        },
        { 
            txt: '冀南军区第3军分区政治部主任甘思和缴获的日军军靴',
            src: '063A4207.png'
        }
    ],
    151:[
        { 
            txt: '1938年3月25日《中央关于大量发展党员的决议》',
            src: '221A4693.png'
        }
    ],
    152:[
        { 
            txt: '1938年10月15日张闻天在中国共产党六届六中全会上的报告提纲——《关于抗日民族统一战线与党的组织问题》部分内容',
            src: '5G3A2139.png'
        },
        { 
            txt: '1938年10月15日张闻天在中国共产党六届六中全会上的报告提纲——《关于抗日民族统一战线与党的组织问题》部分内容',
            src: '5G3A2141.png'
        },
        { 
            txt: '1938年10月15日张闻天在中国共产党六届六中全会上的报告提纲——《关于抗日民族统一战线与党的组织问题》部分内容',
            src: '5G3A2144.png'
        },
    ],
    153:[
        { 
            txt: '1938年5月5日中央关于组织青年工作委员会决定',
            src: '5G3A0887.png'
        }
    ],
    154:[
        { 
            txt: '1948年4月大连大众书店印行陈云著《怎样做一个共产党员》Kjj00704',
            src: '221A4687.png'
        },
        { 
            txt: '红棉出版社印行刘少奇陈云著《怎样做一个共产党员》（假本）Kjj00706',
            src: '221A4682.png'
        },
    ],
    155:[
        { 
            txt: '刘少奇著论共产党员的修养',
            src: '5G3A0883.png'
        },
        { 
            txt: '刘少奇著论共产党员的修养',
            src: '221A4857.png'
        },
        { 
            txt: '刘少奇著论共产党员的修养',
            src: '221A4858.png'
        },
    ],
    156:[
        { 
            txt: '刘少奇著东北书店印行《论共产党员》',
            src: '5G3A2147.png'
        },
        { 
            txt: '中共太岳区党委印刘少奇著《论共产党员在组织与纪律上的修养》',
            src: '5G3A2150.png'
        }
    ],
    157:[
        { 
            txt: '聂荣臻司令员《加强党性锻炼》报告油印本',
            src: '5G3A2158.png'
        },
        { 
            txt: '广东人民抗日游击队东江纵队政治部组织科编增强党性锻炼',
            src: '5G3A0906.png'
        }
    ],
    158:[
        { 
            txt: '《学习是共产党员的责任》手稿',
            src: '5G3A2153.png'
        }
    ],
    159:[
        { 
            txt: '聂荣臻加强党性锻炼报告油印本',
            src: '5G3A0901.png'
        }
    ],
    160:[
        { 
            txt: '中共中央关于增强党性的报告',
            src: '5G3A2162.png'
        }
    ],
    161:[
        { 
            txt: '1939年8月25日《中央政治局关于巩固党的决定》',
            src: '221A4674.png'
        }
    ],
    162:[
        { 
            txt: '共产党人创刊号',
            src: '221A4855.png'
        },
        { 
            txt: '《共产党人》第十九期',
            src: '5G3A0914.png'
        },
        { 
            txt: '《共产党人》第十九期',
            src: '221A4669.png'
        },
    ],
    163:[
        { 
            txt: '中共中央关于统一抗日根据地党的领导及调整各组织间关系的决定',
            src: '5G3A0917.png'
        }
    ],
    164:[
        { 
            txt: '胶东新华书店国民革命军第十八集团军第八路军政治工作条例',
            src: '5G3A0920.png'
        },
        { 
            txt: '谭政著八路军留守兵团政治部印关于军队政治工作问题的报告',
            src: '5G3A0924.png'
        }
    ],
    165:[
        { 
            txt: '中共中央关于增强党性的报告',
            src: '5G3A2164.png'
        }
    ],
    166:[
        { 
            txt: '毛泽东手稿《改造我们的学习》kf1094',
            src: '221A4829.png'
        },
        { 
            txt: '毛泽东手稿《改造我们的学习》kf1094',
            src: '221A4832.png'
        },
        { 
            txt: '毛泽东著农村调查',
            src: '5G3A0934.png'
        },
        { 
            txt: '中共中央书记处编印的六大以前党的历史材料',
            src: '5G3A0938.png'
        },
        { 
            txt: '中共中央宣传部关于在延安讨论中央决定及毛泽东整顿三风报告的决定',
            src: '5G3A0942.png'
        },
        { 
            txt: '郭沫若著太岳书店出版的甲申山百年祭',
            src: '5G3A0947.png'
        },
        { 
            txt: '反对党八股',
            src: '5G3A0949.png'
        },
        { 
            txt: '整顿党的作风',
            src: '5G3A0954.png'
        },
        { 
            txt: '冀中军区教导第二团党支部书记孟整风参考材料',
            src: '5G3A0959.png'
        },
        { 
            txt: '整风学习计划',
            src: '5G3A0966.png'
        },
        { 
            txt: '华北新华书店出版社的整风参考文选',
            src: '5G3A0970.png'
        },
        { 
            txt: '新华书店发行的整风文件',
            src: '5G3A0976.png'
        },
        { 
            txt: '整风学习的进度及具体进行步骤的规定',
            src: '5G3A0979.png'
        },
        { 
            txt: '新四军第三师第八旅第二十四团青年干事唐冀乐使用的笔记本',
            src: '5G3A0989.png'
        },
        { 
            txt: '解放社编渤海新华社书店发行的整风文献订正本',
            src: '5G3A0998.png'
        },
        { 
            txt: '李尚志的整风学习笔记',
            src: '5G3A1004.png'
        },
        { 
            txt: '新华书店晋察冀分店印行的整风文件',
            src: '5G3A1015.png'
        },
        { 
            txt: '王正的整风学习笔记',
            src: '5G3A1019.png'
        }
    ],
    167:[
        { 
            txt: '1942年彭光涵在延安中央海外工作委员会学习班参加整风运动时用的毛毯',
            src: '5G3A1024.png'
        },
        { 
            txt: '党的扩大的六届七中全会原则通过的关于若干历史问题的决议',
            src: '5G3A1032.png'
        }
    ],
    168:[
        { 
            txt: '1945年抗敌剧社社长丁里创作的话剧子弟兵和老百姓排演用本',
            src: '5G3A1053.png'
        }
    ],
    169:[
        { 
            txt: '多种版本的毛泽东著在延安文艺座谈会上的讲话',
            src: '5G3A2177.png'
        },
        { 
            txt: '多种版本的毛泽东著在延安文艺座谈会上的讲话',
            src: '5G3A1057.png'
        },
        { 
            txt: '多种版本的毛泽东著在延安文艺座谈会上的讲话',
            src: '5G3A2175.png'
        },
        { 
            txt: '多种版本的毛泽东著在延安文艺座谈会上的讲话',
            src: '5G3A2182.png'
        },
    ],
    170:[
        { 
            txt: '朱德著华北军政大学政治部印《论解放区战场》',
            src: '5G3A2194.png'
        }
    ],
    171:[
        { 
            txt: '1945年重庆新华日报以报纸形式秘密发行的《论联合政府》kf3598',
            src: '221A4817.png'
        }
    ],
    172:[
        { 
            txt: '多版本的论联合政府',
            src: '5G3A2184.png'
        },
        { 
            txt: '多版本的论联合政府',
            src: '5G3A2187.png'
        },
        { 
            txt: '多版本的论联合政府',
            src: '5G3A2188.png'
        },
        { 
            txt: '多版本的论联合政府',
            src: '5G3A2190.png'
        },
    ],
    173:[
        { 
            txt: '闫子元作为冀中区党委代表准备参加党的“七大”时发的公文包',
            src: '5G3A2499.png'
        },
        { 
            txt: '新华书店发行中国共产党第七次全国代表大会通过《中国共产党党章》kc2034',
            src: '221A4801.png'
        },
        { 
            txt: '七大入场证',
            src: '5G3A1070.png'
        },
        { 
            txt: '严尚林的七大代表证',
            src: '5G3A1074.png'
        },
        { 
            txt: '闫子元作为冀中区党委代表准备参加党的“七大”时发的搪瓷缸子',
            src: '5G3A2493.png'
        },
        { 
            txt: '闫子元作为冀中区党委代表准备参加党的“七大”马褡子',
            src: '5G3A1081.png'
        },
        { 
            txt: '刘少奇著论党即关于修改党章的报告',
            src: '5G3A1087.png'
        },
        { 
            txt: '刘少奇在党的七大上做的关于修改党章的报告',
            src: '5G3A1093.png'
        },
    ],
    174:[
        { 
            txt: '1944年晋察冀日报社编辑出版的首都毛泽东选集',
            src: '5G3A1102.png'
        }
    ],
    175:[
        { 
            txt: '1944年11月5日刊有延安准备反攻内容的参考消息',
            src: '5G3A1096.png'
        }
    ],
    176:[
        { 
            txt: '1945年1月1日广东人民抗日游击队东江纵队政治部编《前进报》第76期',
            src: '5G3A2203.png'
        },
        { 
            txt: '正义报社出版毛泽东同志在边区参议会的演说《一九四五年的任务》',
            src: '5G3A2196.png'
        },
    ],
    177:[
        { 
            txt: '毛泽东为崔万生题词的笔记本',
            src: '5G3A1110.png'
        },
        { 
            txt: '朱德为崔万生题词的笔记本',
            src: '5G3A1112.png'
        },
        { 
            txt: '周恩来为崔万生题词的笔记本',
            src: '5G3A1114.png'
        },
    ],
    178:[
        { 
            txt: '董必武编美国旧金山出版社的中国解放区英文版',
            src: '5G3A1122.png'
        }
    ],
    179:[
        { 
            txt: '朱德总司令发布的《延安总部命令》jf004242',
            src: '221A4662.png'
        },
        { 
            txt: '周恩来起草的反攻命令手稿',
            src: '5G3A1135.png'
        },
        { 
            txt: '周恩来起草的反攻命令手稿',
            src: '5G3A1140.png'
        },
    ],
    180:[
        { 
            txt: '《民兵》第138期：日寇无条件投降盟国，朱总司令命令各部队立即进行接洽敌伪受降事宜',
            src: '5G3A2228.png'
        },
        { 
            txt: '胶东军区司令部编反攻后胶东情况总结报告',
            src: '5G3A2224.png'
        }
    ],
    181:[
        { 
            txt: '1945年8月12日《晋察冀日报》刊载的1945年8月11日晋察冀军区、山东军区向日军发出受降通牒',
            src: '5G3A2221.png'
        },
        { 
            txt: '太岳军区对日本驻军通牒',
            src: '5G3A2217.png'
        },
        { 
            txt: '新四军苏浙军区对日本驻军通牒',
            src: '5G3A2215.png'
        },
    ],
    182:[
        { 
            txt: '1945年7月7日冀鲁豫军区第七军分区颁发的抗战八年纪念馆',
            src: '5G3A1155.png'
        },
        { 
            txt: '刊载关于抗战8周年的中国共产党的主张社论的解放日报',
            src: '5G3A1157.png'
        },
        { 
            txt: '刊载胶东八路军攻抵威海卫城郊收复威海卫外围据点二十三处 解放日报',
            src: '5G3A1166.png'
        },
        { 
            txt: '冀鲁豫我军大胜利光复濮阳县城解放日报',
            src: '5G3A1170.png'
        },
        { 
            txt: '滨海我军光复县苏中江都高邮地区我军攻解放日报',
            src: '5G3A1176.png'
        },
    ],
    184:[
        { 
            txt: '大东亚战争下的国际形势',
            src: '5G3A1263.png'
        },
        { 
            txt: '1945年苏中出版社出版的毛泽东选集',
            src: '5G3A1293.png'
        }
    ],
    185:[
        { 
            txt: '1944年出版的中国敌后解放区概况',
            src: '5G3A1251.png'
        },
        { 
            txt: '1945年第十八集团军总政治部宣传部编印的抗战八年来的八路军与新四军',
            src: '5G3A1254.png'
        },
        { 
            txt: '朱德著1945年联政出版的论解放区战场',
            src: '5G3A1258.png'
        },
    ],
    186:[
        { 
            txt: '1948年香港正报社出版的中国革命中国共产党',
            src: '5G3A1244.png'
        },
        { 
            txt: '1949年中共北平市委员会宣传部出版的中国革命与中国共产党',
            src: '5G3A1247.png'
        }
    ],
    187:[
        { 
            txt: '1942年解放出版社编辑出版的毛泽东著《论新阶段》',
            src: '221A5053.png'
        },
        { 
            txt: '1938年解放出版社的论持久战',
            src: '5G3A1275.png'
        },
        { 
            txt: '1945年苏中出版社出版的新民主主义论',
            src: '5G3A1279.png'
        },
    ],
}