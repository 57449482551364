export default {
    inserted (el,binding,vnode) {
    let switchPos = {
        transformData: {
            x: 0,
            y: 0,
            scale: 1
        },
        maxScale: 4,
        minScale: 0.5,
        damping: false,
        perspective: false,
        store: {
            x: 0,
            y: 0,
            distance: 1
        },
        touchType: '',
        scaleTranslateProportion: [], 
    }
    const touchstart = function(e){
        e.preventDefault();
        if (e.touches.length === 1) {
            // 当前屏幕上只有一个触摸点的时候就是移动
            moveStart(e.touches[0].clientX, e.touches[0].clientY);
            switchPos.touchType = "move";
        } 
        else if(e.touches.length === 2) {
            // 如果两个触摸点是缩放
            scaleStart(e.touches);
            switchPos.touchType = "scale";
            // window.alert('2')
        }
    }
    const touchmove=(e)=> {
        e.preventDefault();
            if (e.touches.length === 1 && switchPos.touchType === "move" && switchPos.transformData.scale != 1) {
                // 如果屏幕上只有一个触摸点而且类型是移动的时候才是移动
                move(e.touches[0].clientX, e.touches[0].clientY);
            } else if (e.touches.length === 2) {
                // 只要有两个触摸点就是缩放,可以从移动转换成缩放
                scale(e.touches);
            }
    }
    // 更改移动缩放的效果
    const setTransform=()=> {
        // const _this = this;
        // window.alert(ty + '到这儿了！')
    // 奇奇怪怪的效果
        if (switchPos.damping) {
            // _this.viewSize = `transition: transform .3s`
            el.style.transition = "transform .3s"
        } else {
            // _this.viewSize = `transition: none`
            el.style.transition = "none"
        }
        // console.log( _this.transformData.scale,'this.transformData.scale~~~' )
        // window.alert( this.transformData.scale )
        // 先平移再缩放
        // _this.viewSize = { transform: `${this.perspective ? 'perspective(500px) rotateX(50deg) skewX(-10deg);' : ''} translate(${this.transformData.x || 0}px, ${this.transformData.y || 0}px) scale(${this.transformData.scale || 0}, ${this.transformData.scale || 0})` }
        el.style.transform = `
            ${switchPos.perspective ? 'perspective(500px) rotateX(50deg) skewX(-10deg)' : ''}
            translate(${Math.round(switchPos.transformData.x) || 0}px, ${Math.round(switchPos.transformData.y) || 0}px)
            scale(${Math.round(switchPos.transformData.scale) || 0})`;
            el.style.filter = 'none';
            el.style[' -webkit-filter'] = 'none';
            binding.value(vnode.context, switchPos.transformData.scale );
    }
    // 开始移动的方法: 记录首次的数据
    const moveStart=(x, y)=> {
        // window.alert('1')
        switchPos.store.x = x;
        switchPos.store.y = y;
    }
    // 移动的方法: 上一次减去当前为偏移量
    const move=(x, y)=> {
        let width = vnode.context.viewSizeWidth * switchPos.transformData.scale / 2 - 100;
        let height = vnode.context.viewSizeHeight - 100;
        switchPos.transformData.x += Math.abs(switchPos.transformData.x + x - switchPos.store.x) >  width ? 0 : x - switchPos.store.x;
        switchPos.transformData.y += Math.abs(switchPos.transformData.y + y - switchPos.store.y) >  height ? 0 : y - switchPos.store.y
        switchPos.store.x = x;
        switchPos.store.y = y;
        // setScale(2);
        setTransform();
    }

    // 开始移动: 记录首次的触摸数据,和中心缩放比例
    const scaleStart=(touchList)=> {
        // 算出当前两指之间的距离
        const x = touchList[0].clientX - touchList[1].clientX;
        const y = touchList[0].clientY - touchList[1].clientY;
        switchPos.store.distance = Math.sqrt(x ** 2 + y ** 2);
        // 缩放中心为双指点的中心,此时的双指中心只是touchBox上的,得转换成transformDom上的,
        // 因为缩放中心的位置带来translate的变化,是根据当前触摸中心在transformDom上的比例算出来的
        const scaleCenter = [
            (touchList[0].clientX + x / 2 - switchPos.transformData.x) / switchPos.transformData.scale,
            (touchList[0].clientY + y / 2 - switchPos.transformData.y) / switchPos.transformData.scale,
        ];
        // 缩放导致偏移的比例
        switchPos.scaleTranslateProportion = [
            scaleCenter[0] / switchPos.transformDom.offsetWidth,
            scaleCenter[1] / switchPos.transformDom.offsetHeight,
        ];
    }
    // 进行缩放操作
    const scale=(touchList)=> {

    // 开始时move后面scale的情况下会没有上一次的scale数据,所以把这次当做start
        if (switchPos.touchType !== 'scale') {
            switchPos.touchType = "scale";
            scaleStart(touchList);
            return
        }
    // 算出当前两指的距离
        const distance = Math.sqrt(
            (touchList[0].clientX - touchList[1].clientX) ** 2 +
            (touchList[0].clientY - touchList[1].clientY) ** 2
        );
        // window.alert(distance +'aaaa')
        // 缩放大小为现在的两指距离除去上次的两指距离
        doscale(distance / switchPos.store.distance, false)
        // 记录这一次两指距离
        switchPos.store.distance = distance;

    }
// 进行指定大小的缩放
    const doscale=(scale, useCenter = true)=> {
        // 为0或者为1就不进行缩放
        if (scale === 0 && scale === 1) return
        // 缩放前的transformDom大小
        const oldSize = [
            el.offsetWidth * switchPos.transformData.scale,
            el.offsetHeight * switchPos.transformData.scale,
        ];
        // window.alert( oldSize + 'touchList')
        let scaleTranslateProportion = switchPos.scaleTranslateProportion
        // 如果直接操作,不是双指进行缩放就设置touchDom中心是缩放中心
        if (useCenter) {
        // touchDom的中心,
        const scaleCenter = [
            (switchPos.touchDom.offsetWidth / 2 - switchPos.transformData.x) / switchPos.transformData.scale,
            (switchPos.touchDom.offsetHeight / 2 - switchPos.transformData.y) / switchPos.transformData.scale,
        ];
        // 缩放导致偏移的比例
        scaleTranslateProportion = [
            scaleCenter[0] / switchPos.transformDom.offsetWidth,
            scaleCenter[1] / switchPos.transformDom.offsetHeight,
        ];
        }
        // 设置缩放的偏移,之前纠结在使用两指的偏移位置来计算,实际上缩放后大小的变化不是两指间移动的距离
        // 变化大小其实就是缩放的大小乘原来的大小
        switchPos.transformData.x +=
            oldSize[0] *
            (1 - scale) *
            scaleTranslateProportion[0] || 0;
            switchPos.transformData.y +=
            oldSize[1] *
            (1 - scale) *
            scaleTranslateProportion[1] || 0;
        // 设置缩放
        switchPos.transformData.scale *= scale;
        // setScale(2);
        setTransform();
    }// 放大操作
    const enlargeScale=(size = 1.2)=> {
        // 如果没有超过限制就正常缩放,超过了就缩放到限制大小
        if (switchPos.transformData.scale * size <= switchPos.maxScale) {
            doscale(size)
        } else {
            doscale(switchPos.maxScale / switchPos.transformData.scale)
        }
    }
    // 缩小操作
    const narrowScale=(size = 0.8)=> {
        if (switchPos.transformData.scale * size >= switchPos.minScale) {
            doscale(size)
        } else {
            doscale(switchPos.minScale / switchPos.transformData.scale)
        }
    }
    const touchend=()=> {
        switchPos.store = {
            x: 0,
            y: 0,
            distance: 0,
        }
        switchPos.touchType = ''
        if (switchPos.transformData.scale > switchPos.maxScale) {
            doscale(switchPos.maxScale / switchPos.transformData.scale, false)
        } 
        if (switchPos.transformData.scale < switchPos.minScale) {
            doscale(switchPos.minScale / switchPos.transformData.scale, false)
        }
    }
    const getTransformData=()=> {
        return switchPos.transformData
    }
    const setPerspective=(value)=> {
        switchPos.perspective = value
        setTransform()
    }
    const setDamping=(value)=> {
        switchPos.damping = value
        setTransform()
    }
    const distory=()=> {
    //   this.touchDom.removeEventListener('touchstart', this.touchstart.bind(this),false)
    //   this.touchDom.removeEventListener('touchmove', this.touchmove.bind(this),false)
    //   this.touchDom.removeEventListener('touchend', this.touchend.bind(this),false)
    }

    const getDistance=(start, stop)=> {
    // Math.hypot()计算参数的平方根
        return Math.hypot(stop.x - start.x, stop.y - start.y);
    }
        el.addEventListener('touchstart', touchstart)
        el.addEventListener('touchend', touchend)
        el.addEventListener('touchmove', touchmove)
    }
}