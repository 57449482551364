import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // Home.vue
  {
    path: '/pc_index/:id',
    name: 'pc_index',
    component: () => import( '../views/pcIndex.vue')
  },
  {
    path: '/:id',
    name: 'pc_index',
    component: () => import( '../views/pcIndex.vue')
  },
  // About.vue
  {
    path: '/m_index/:id',
    name: 'm_index',
    component: () => import('../components/mobileIndex.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
