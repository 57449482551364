<template>
    <v-touch
        v-on:swipeleft="onSwipeLeft" 
        v-on:swiperight="onSwipeRight"
    >
        <div tag="div" class="articleList articleListMod-3 er-carouseindex" name="slide-fade" id="articleList" :style="{ width: bodyWidth - 10 + 'px', height: bodyHeight - 18 + 'px' }">
            <img class="pcBag" src="@/assets/background/m_bag.png" :style="{  width: bodyWidth + 'px', height: bodyHeight + 'px' }"/>
            <div class="pcImageShow-top" id="pcImageShowTop">
                <img class="buttonClick m_leftImge" src="@/assets/background/m_left.png" @click="removeIndex()" :style="leftPos" />
                <div class="viewSize" id="viewSize" >   
                    <img src="@/assets/background/shuiyin.png" class="shuiyin"/>
                    <img   
                        :id="index === imgShowIndex ? 'm_centerImg': ''"
                        v-for="(item,index) in imgList" v-show="index === imgShowIndex" :key="index" 
                        :style="[test,viewSize]" class="m_centerImg"
                        :src="require(`../assets/img/${item.src}`)" :class="centerClass" 
                        v-drag="restImg"
                        :ref="index === imgShowIndex ? 'm_centerImg ': ''"
                    />
                </div>
                <img class="buttonClick m_rightImge" src="@/assets/background/m_right.png" @click="addIndex()" :style="rightPos"/>
            </div>
                <div class="pcImageShow-bottom">
                    <p class="text">{{imgList[imgShowIndex].txt}}</p>
                </div>
            <div class="page">{{ imgShowIndex + 1  + '/' + imgList.length}}</div>
        </div>
  </v-touch>
</template>

<script>
import { removeWatermark, setWaterMark } from '@/until/watermark';
    export default
    {
        name: "Mindex",
        data(){
            return {
                test: '',
                centerClass:'',
                imgList: [],
                imgShowIndex: 0,
                introduce:'',
                bodyWidth: document.documentElement.clientWidth,
                bodyHeight: document.documentElement.clientHeight, //窗口高度;
                params: {
                    zoomVal: 1,
                    left: 0,
                    top: 0,
                    currentX: 0,
                    currentY: 0,
                    flag: false,
                },
                pictShow: false,
                zoomInShow: false,
                leftPos:'',
                rightPos:'',
                displacement: {
                    scale: 1
                },
                touchDom: document.getElementById('viewSize'),
                transformDom: document.getElementById('m_centerImg'),
                transformData: {
                    x: 0,
                    y: 0,
                    scale: 1
                },
                maxScale: Infinity,
                minScale: 0,
                damping: false,
                perspective: false,
                store: {
                    x: 0,
                    y: 0,
                    distance: 1
                },
                touchType: '',
                scaleTranslateProportion: [],
                viewSize:'',
                ttssss:0,
                scale:1,
                viewSizeHeight: 0,
                viewSizeWidth: 0
            }
        },
        props:{
          
        },
        computed: {
            classStyle() {
                return this.zoomInShow ? 'a1' : 'a2'
            },
        },

    mounted() {
        this.$nextTick(() => {
            this.imgView();
        })
    },
    destroyed() {
        removeWatermark();
    },
        methods:{
            //页面初始化复赋值
            imgView:function() {
                let id = this.$route.query.id;
                const _this = this;
                let list = require('../until/image1.js').imageObj[id];
                _this.imgList = list;
                _this.introduce = list[0].txt;
                let img = new Image();
                img.src = require(`../assets/img/${list[0].src}`);
                // 加载完成执行
                img.onload = function(){
                    _this.setImgSize(img);
                }
            },
            onSwipeLeft:function(){
                if( this.scale == 1 ){
                    this.addIndex();
                }
            },
            onSwipeRight:function(){
                if( this.scale == 1 ){
                    this.removeIndex();
                }
            },
            onpinch:function(){
                // this.removeImg()
            },
            setImgSize:function(img, that ){
                const _this = that ? that : this;
                removeWatermark();
                // 获取手机可视高度
                const clientHeight = document.body.clientHeight
                // 获取可视宽度
                const clientWidth = document.body.clientWidth;
                // 获取图片宽高
                // let img = new Image();
                // img.src = require(`../assets/img/${_this.imgList[_this.imgShowIndex].src}`);
                // console.log( img, img.width, img.height,'~~~' )
                // 计算图片宽高比例
                let aspectRatio = img.width / img.height;
                // 对比图片宽高
                    // if( img.width > img.height ){
                        let width = clientWidth - 80;
                        let height = width / aspectRatio;
                        _this.test = {width: `${width}px`, left: '0px',top: '50%','margin-top': `-${height/2}px`}; 
                        _this.leftPos = `left: 14px; top: 50%; margin-top:-${18}px;` ;
                        _this.rightPos = `right: 20px; bottom: 50%; margin-bottom:-${18}px;`
                        // document.getElementById('pcImageShowBottom').style.height = `${clientHeight - (height + 40 < 400 ? 400 : height + 40)}px`;
                        document.getElementById('pcImageShowTop').style.height = `${clientHeight - 240}px`;
                        document.getElementById('viewSize').style.height = `${clientHeight - 240}px`;
                        document.getElementById('viewSize').style.marginTop = `-${(clientHeight - 280)/2}px`;
                        this.viewSizeHeight = clientHeight - 240;
                        this.viewSizeWidth = width;
                    // }else{
                    //     let height = clientHeight - 160 - 40;
                    //     let width = height * aspectRatio;
                    //     _this.test = `height: ${height}px;  top: 20px; left: 50%;margin-left: -${width/2}px` 
                    //     _this.leftPos = `top: ${(height / 2) - 18 }px; left: 20px` ;
                    //     _this.rightPos = `top: ${(height / 2) - 18 }px; right: 20px` ;
                    // }
                    // console.log( _this.test,'_this.test' )
                // 如果宽大于高{
                //     定义最大宽度
                //     设置高度
                //     设置按钮位置
                //     设置底部展示文案位置
                // }

                // 如果高大于宽{
                //     定义最大高度
                //     设置宽度
                //     设置按钮位置
                //     设置底部展示文案位置
                // }
                setWaterMark('中国人民抗日战争纪念馆');


            },
            restImg:function(that, scale ){
                const _this = that;
                let index = _this.imgShowIndex;
                _this.imgShowIndex = index;
                let img = new Image();
                img.src = require(`../assets/img/${_this.imgList[index].src}`);
                img.onload = function(){
                    _this.setImgSize(img, that );
                }
                if( this.params.zoomVal !== 1 ){
                    this.params.zoomVal = 1;
                    this.test = {...this.test, transform:scale(`${this.params.zoomVal}`) };
                }
                this.scale = scale;
                this.viewSize = '';
            },
            //加页
            addIndex: function(){
                const _this = this;
                let index = _this.imgShowIndex + 1 == _this.imgList.length ? 0 : _this.imgShowIndex + 1;
                _this.imgShowIndex = index;
                let img = new Image();
                img.src = require(`../assets/img/${_this.imgList[index].src}`);
                img.onload = function(){
                    _this.setImgSize(img);
                }
                if( this.params.zoomVal !== 1 ){
                    this.params.zoomVal = 1;
                    this.test = {...this.test, transform:scale(`${this.params.zoomVal}`) };
                }
                this.scale = 1;
                this.viewSize = '';
            },
             removeIndex: function(){
                const _this = this;
                let index = _this.imgShowIndex == 0 ? _this.imgList.length - 1 : _this.imgShowIndex - 1;
                _this.imgShowIndex = index;
                let img = new Image();
                img.src = require(`../assets/img/${_this.imgList[index].src}`);
                img.onload = function(){
                    _this.setImgSize(img);
                }
                if( this.params.zoomVal !== 1 ){
                    this.params.zoomVal = 1;
                    this.test = {...this.test, transform:scale(`${this.params.zoomVal}`) };
                }
                this.scale = 1;
                this.viewSize = '';
            },
            bbimg:function() {
                let e = e || window.event
                this.params.zoomVal += e.wheelDelta / 1200
                if (this.params.zoomVal >= 0.2) {
                    this.test = `transform:scale(${this.params.zoomVal});`
                } else {
                    this.params.zoomVal = 0.2
                    this.test = `transform:scale(${this.params.zoomVal});`
                    return false
                }
            },
            bigImg:function() {
                // let e = e || window.event
                this.params.zoomVal += 0.2;
                if (this.params.zoomVal >= 0.2) {
                    this.test = `transform:scale(${this.params.zoomVal});`
                } else {
                    this.params.zoomVal = 0.2
                    this.test = `transform:scale(${this.params.zoomVal});`
                    return false
                }
            },
            removeImg:function() {
                // let e = e || window.event
                this.params.zoomVal -= 0.2;
                if (this.params.zoomVal >= 0.2) {
                    this.test = `transform:scale(${this.params.zoomVal});`
                } else {
                    this.params.zoomVal = 0.2
                    this.test = `transform:scale(${this.params.zoomVal});`
                    return false
                }
            },
            // restImg:function() {
            //     // let e = e || window.event
            //     // this.params.zoomVal += 0.2;
            //     // if (this.params.zoomVal >= 0.2) {
            //     //     this.test = `transform:scale(${this.params.zoomVal});`
            //     // } else {
            //         this.params.zoomVal = 1
            //         this.test = `transform:scale(${this.params.zoomVal});`
            //     //     return false
            //     // }
            // },
        },
    }
</script>
<style scoped>
    .articleList{
        width: calc(100%);
        position: relative;
        /* background: url('../assets/background/pcBag.png'), no-repeat, center; */
    }
    .pcBag{
        width: calc(100%);
        /* height: 100%; */
        z-index: -1;
        position: fixed;
        left: 0;
        top: 0;
        /* display: flex; */
    }
    .viewSize{
        width: calc( 100% - 80px );
        /* height: 300px; */
        overflow: hidden;
        position: absolute;
        left: 40px;
        top: 50%;
        /* margin-top: -150px; */
    }
    .pcImageShow-top{
        width: 100%;
        height: 70%;
        position: relative;
    }
    .pcImageShow-bottom{
        width: calc( 100% - 40px );
        /* height: 25%; */
        height: 180px;
        /* line-height: 100%; */
        /* height: 100%; */
        margin-left: 20px;
        position: absolute;
        bottom: 0;
    }
    .buttonClick{
        /* width: 32px; */
        /* height: 37px; */
        position: absolute;
        z-index: 10;
    }
    .m_leftImge{
        position: absolute;
        left: 24px;
        /* bottom: -19px; */
         top: 50%;
        margin-top: -19px;
    }
    .m_rightImge{
        position: absolute;
        right: 24px;
        /* bottom: -19px; */
        top: 50%;
        margin-top: -19px;
    }
    .m_centerImg{
        /* width: calc( 100% - 40px ); */
        /* max-height: 48%; */
        position: absolute;
        /* left: 20px; */
        /* bottom: 20px; */
        /* left: 50%; */
        /* margin-left: -20px; */
        /* margin-top: -19px; */
        -webkit-filter: drop-shadow(4px 4px 4px rgba(0,0,0,.5));
         /*考虑浏览器兼容性：兼容 Chrome, Safari, Opera */
        filter: drop-shadow(4px 4px 4px rgba(0,0,0,.5));
        /* box-shadow: 4px 4px 4px rgba(0,0,0,.5); */
        /*考虑浏览器兼容性*/
        /* -moz-box-shadow:4px 4px 4px rgba(0,0,0,.5); */
        /* -webkit-box-shadow: 4px 4px 4px rgba(0,0,0,.5); */
    }
    .wImge{
        width: calc( 100% - 40px );
        /* height: 400px;
        position: absolute; */
        left: 20px;
        top: 20%;
        /* margin-bottom: -200px; */
        -webkit-filter: drop-shadow(8px 8px 8px rgba(0,0,0,.5));
        filter: drop-shadow(8px 8px 8px rgba(0,0,0,.5));
    } 
    .hImge{
        /* max-height: calc( 100% - 40px ); */
        position: absolute;
        bottom: 20px;
        -webkit-filter: drop-shadow(8px 8px 8px rgba(0,0,0,.5));
        filter: drop-shadow(8px 8px 8px rgba(0,0,0,.5));
    }
    .bottomButton{
        width: 270px;
        height: 38px;
        position: absolute;
        bottom: 80px;
        left: 50%;
        margin-left:  -109px;
        /* display: flex; */
    }
    .pcImageShow-center{
        width: 0px;
        height: calc(100% - 130px);
        margin-top: 65px;
        border: 2px solid;
        border-image: linear-gradient(180deg, rgba(206, 189, 167, 0), rgba(83, 34, 19, 0.7), rgba(83, 34, 19, 1), rgba(255, 255, 255, 0)) 4 4;
    }
    .operation{
        /* width: 38px;
        height: 38px; */
        flex: 1;
        margin-right: 30px;
    }
    .text{
        width: 76%;
        /* max-width: 218px; */
        position: absolute;
        top: 0;
        /* margin-top: -30px; */
        text-align: center;
        left: 12%;
        font-size: 16px;
        /* font-family: PingFangSC-Semibold, PingFang SC; */
        font-weight: 600;
        color: #37160C;
        /* line-height: 42px; */
    }
    .page{
        width: 80px;
        height: 24px;
        text-align: center;
        font-size: 16px;
        /* font-family: PingFangSC-Semibold, PingFang SC; */
        font-weight: 600;
        color: #532213;
        line-height: 24px;
        position: absolute;
        left: 50%;
        margin-left: -40px;
        bottom: 20px;
    }
.a1 {
  max-width: 200vw;
  max-height: 180vh;
  position: absolute;
  z-index: 22;
  /* margin-top: 40px; */
  cursor: move;
}
.a2 {
  max-width: 95vw;
  max-height: 90vh;
  position: absolute;
  z-index: 22;
  /* margin-top: 40px; */
  cursor: move;
}
.zoom-box {
  cursor: zoom-in;
}
.photo_box {
  margin: 0 5px 5px 0;
}
</style>




