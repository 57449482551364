<template>
    <div tag="div" class="articleList articleListMod-3 er-carouseindex" name="slide-fade" id="articleList" :style="{ width: bodyWidth - 10 + 'px', height: bodyHeight - 30 + 'px' }" @mousewheel.prevent="rollImg">
        <img class="pcBag" src="@/assets/background/pcBag.png" :style="{  width: bodyWidth + 'px', height: bodyHeight + 'px' }"/>
        <div class="pcImageShow-left" ref="barparent" id="pcImageShowTop">
            <img class="buttonClick leftImge" src="@/assets/background/next.png" @click="removeIndex()" :style="leftPos"/>
            <div class="maxWidth" :style="maxWidth" ref="maxWidth">
                <img class="centerImg"
                     :id="index === imgShowIndex ? 'm_centerImg': ''"
                     v-for="(item,index) in imgList" 
                     v-show="index === imgShowIndex" 
                     :src="require(`../assets/img/${item.src}`)" 
                     :key="index" 
                     :style="test" 
                     v-pcDrag="elementPos" 
                     ref="imgDiv"
                />
            </div>
            <img class="buttonClick rightImge" src="@/assets/background/pro.png" @click="addIndex()" :style="rightPos"/>
            <div class="bottomButton">
                <img class="operation" src="@/assets/background/add.png" @click="bigImg"/>
                <img class="operation" src="@/assets/background/remove.png"  @click="removeImg" />
                <img class="operation" src="@/assets/background/rest.png"  @click="restImg"/>
            </div>
        </div>
        <div class="pcImageShow-center"></div>
        <div class="pcImageShow-right">
            <p class="text">{{imgList.length > 0 ? imgList[imgShowIndex].txt : ''}}</p>
        </div>
        <div class="page">{{ imgShowIndex + 1  + '/' + imgList.length}}</div>
    </div>
</template>

<script>
    import { removeWatermark, setWaterMark } from '@/until/watermarkpc';
    export default
    {
        name: "ErCarouselIndex",
        data(){
            return {
                test: '',
                imgList: [],
                imgShowIndex: 0,
                introduce:'',
                bodyWidth: document.documentElement.clientWidth,
                bodyHeight: document.documentElement.clientHeight, //窗口高度;
                params: {
                    zoomVal: 1,
                    left: 0,
                    top: 0,
                    currentX: 0,
                    currentY: 0,
                    flag: false,
                },
                pictShow: false,
                zoomInShow: false,
                leftPos:'',
                rightPos:'',
                maxWidth:'',
                timer:false,
                elementPos: {
                    left: null,
                    top: null,
                },
                viewSizeHeight: 0,
                viewSizeWidth: 0
            }
        },
        props:{
          
        },
        computed: {
            classStyle() {
                return this.zoomInShow ? 'a1' : 'a2'
            },
        },
        watch:{
            bodyWidth(val){
                // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
                if(!this.timer){
                    // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
                    this.bodyWidth = val
                    this.timer = true
                    let that = this
                    setTimeout(function(){
                        // 打印screenWidth变化的值
                        that.timer = false
                    },400)
                }
            },
            bodyHeight(val){
                // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
                if(!this.timer){
                    // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
                    this.bodyHeight = val;
                    this.timer = true;
                    let that = this;
                    setTimeout(function(){
                        // 打印screenWidth变化的值
                        that.timer = false
                    },400)
                }
            },
            elementPos:{
                handler(val){
                    console.log(val, 789);// 实时获取拖拽元素相对于父级位置
                },
                deep: true, immediate: true
            }

        },
        methods:{
            doubletap:function(){
                console.log( 'doubletap' )
                if(  this.params.zoomVal >= 1 ){
                   this.removeImg()
                }else{
                    this.bigImg()
                }
            },
            //页面初始化复赋值
            imgView:function() {
                let id = this.$route.query.id;
                console.log( id,'~~~' )
                const _this = this;
                console.log( '页面' );
                let list = require('../until/image1.js').imageObj[id];
                _this.imgList = list;
                _this.introduce = list[0].txt;
                let img = new Image();
                img.src = require(`../assets/img/${list[0].src}`);
                // 加载完成执行
                img.onload = function(){
                    _this.setImgSize(img);
                }
            },
            //加页
            addIndex: function(){
                const _this = this;
                let index = _this.imgShowIndex + 1 == _this.imgList.length ? 0 : _this.imgShowIndex + 1;
                _this.imgShowIndex = index;
                let img = new Image();
                img.src = require(`../assets/img/${_this.imgList[index].src}`);
                console.log( img.width,img.height,'img~~~' )
                img.onload = function(){
                    _this.setImgSize(img);
                }
                if( this.params.zoomVal !== 1 ){
                    this.params.zoomVal = 1;
                    this.test = this.test + `transform:scale(${this.params.zoomVal});`
                }
            },
            removeIndex: function(){
                const _this = this;
                 let index = _this.imgShowIndex == 0 ? _this.imgList.length - 1 : _this.imgShowIndex - 1;
                _this.imgShowIndex = index;
                let img = new Image();
                img.src = require(`../assets/img/${_this.imgList[index].src}`);
                img.onload = function(){
                    _this.setImgSize(img);
                }
                if( this.params.zoomVal !== 1 ){
                    this.params.zoomVal = 1;
                    this.test = this.test + `transform:scale(${this.params.zoomVal});`
                }
            },
             setImgSize:function(img){
                const _this = this;
                // 获取手机可视高度
                const clientHeight = document.body.clientHeight
                // 获取可视宽度
                // const clientWidth = document.body.clientWidth;
                const clientWidth = this.$refs.barparent.clientWidth;;
                console.log( clientHeight,'clientWidth~~~' )
                // 获取图片宽高
                console.log( img, img.width, img.height,'~~~' )
                // 计算图片宽高比例
                let aspectRatio = img.width / img.height;
                // 对比图片宽高
                let width = clientWidth - 120;
                let height = width / aspectRatio;
                if( clientHeight - height < 200 ){
                    height = clientHeight - 200;
                    width = height * aspectRatio;
                     _this.test = `width: ${width}px; left: 50%; margin-left: -${width/2}px;` 
                    _this.leftPos = `left: 20px; top: 50%; margin-top:-${18}px` ;
                    _this.rightPos = `right: 20px; bottom: 50%; margin-bottom:-${18}px` ;
                    _this.maxWidth = `width: ${width}px; height: ${height}px; left: 50%; margin-left: -${width/2}px; top: 50%; margin-top: -${height/2}px`;
                    _this.viewSizeHeight =  width,
                    _this.viewSizeWidth = height
                }else{
                     _this.test = `width: ${width}px; left: 12px;top: 50%; margin-top: -${height/2}px` 
                    _this.leftPos = `left: 20px; top: 50%; margin-top:-${18}px` ;
                    _this.rightPos = `right: 20px; bottom: 50%; margin-bottom:-${18}px` ;
                    _this.maxWidth = `width: ${width}px; height: ${height}px; left:60px;top: 50%;margin-top: -${height/2}px`;
                    _this.viewSizeHeight =  width,
                    _this.viewSizeWidth = height
                }
                 setWaterMark('中国人民抗日战争纪念馆');
            },
            rollImg() { 
                if( event.wheelDelta > 0 ){
                    this.bigImg()
                }else{
                    this.removeImg()
                }
            },
            bigImg:function() {
                console.log('走了吧')
                // let e = e || window.event
                this.params.zoomVal += 0.2;
                if (this.params.zoomVal >= 0.2) {
                    this.test = this.test + `transform:scale(${this.params.zoomVal});`
                } else {
                    this.params.zoomVal = 0.2
                    this.test = this.test + `transform:scale(${this.params.zoomVal});`
                    return false
                }
            },
            removeImg:function() {
                // let e = e || window.event
                this.params.zoomVal -= 0.2;
                if (this.params.zoomVal >= 0.2) {
                    this.test = this.test + `transform:scale(${this.params.zoomVal});`
                } else {
                    this.params.zoomVal = 0.2
                    this.test = this.test + `transform:scale(${this.params.zoomVal});`
                    return false
                }
            },
            restImg:function() {
                    const _this = this;
                    let img = new Image();
                    img.src = require(`../assets/img/${_this.imgList[_this.imgShowIndex].src}`);
                    img.onload = function(){
                        _this.setImgSize(img);
                    }
                    this.params.zoomVal = 1
                    this.test = `transform:scale(${this.params.zoomVal});`
            },
        },
        mounted()
        {   
            const _this = this;
            this.$nextTick(function () {
                this.imgView();
            });
            window.onresize = () => {
                    return (() => {
                        window.bodyWidth = document.documentElement.clientWidth;
                        window.bodyHeight = document.documentElement.clientHeight;
                        _this.bodyWidth = window.bodyWidth;
                        _this.bodyHeight = window.bodyHeight;
                        _this.imgView();
                    })()
                }
        }
    }
</script>
<style scoped>
    .articleList{
        width: 100%;
        position: relative;
        /* background: url('../assets/background/pcBag.png'), no-repeat, center; */
        display: flex;
    }
    .pcBag{
        width: 100%;
        /* height: 100%; */
        z-index: -1;
        position: fixed;
        left: 0;
        top: 0;
        /* display: flex; */
    }
    .pcImageShow-left{
        width: calc( 51% - 25px);
        height: calc( 100% - 40px);
        margin: 20px 0 20px 20px;
        /* padding-top: 20px; */
        float: left;
        position: relative;
        overflow: hidden;
    }
    .pcImageShow-right{
        width: calc( 49% - 4px );
        line-height: 100%;
        height: 100%;
        float: left;
        position: relative;
    }
    .buttonClick{
        width: 32px;
        height: 37px;
        position: absolute;
        z-index: 10;
    }
    .leftImge{
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -19px;
    }
    .rightImge{
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -19px;
    }
     .maxWidth{
        position: absolute;
        /* width: 80%;
        height: 400px;
        position: absolute;
        top: 50%;
        margin-top: -200px;
        left: 10%; */
        /* margin-left: -200px; */
        overflow: hidden;
        display: flex;
        /* position: relative; */
    }
    .centerImg{
        /* width: 400px; */
        /* height: 400px; */
        /* max-width: 400px;
        max-height: 400px; */
        position: absolute;
        /* top: 50%;
        margin-top: -200px;
        left:50%;
        margin-left: -200px; */
        /* border: 1px solid #000; */
        -webkit-filter: drop-shadow(8px 8px 8px rgba(0,0,0,.5)); /*考虑浏览器兼容性：兼容 Chrome, Safari, Opera */
        filter: drop-shadow(8px 8px 8px rgba(0,0,0,.5));
        cursor: pointer;
    }
    .bottomButton{
        width: 270px;
        height: 38px;
        position: absolute;
        bottom: 27px;
        left: 50%;
        margin-left: -90px;
        /* display: flex; */
    }
    .pcImageShow-center{
        width: 0px;
        height: calc(100% - 130px);
        margin-top: 65px;
        border: 2px solid;
        border-image: linear-gradient(180deg, rgba(206, 189, 167, 0), rgba(83, 34, 19, 0.7), rgba(83, 34, 19, 1), rgba(255, 255, 255, 0)) 4 4;
    }
    .operation{
        /* width: 38px;
        height: 38px; */
        flex: 1;
        margin-right: 30px;
    }
    .text{
        width: 76%;
        position: absolute;
        top: 50%;
        margin-top: -30px;
        text-align: center;
        left: 12%;
        font-size: 30px;
        font-weight: 600;
        color: #37160C;
        line-height: 42px
    }
    .page{
        width: 74px;
        height: 53px;
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        color: #532213;
        line-height: 53px;
        position: absolute;
        left: 50%;
        margin-left: -37px;
        bottom: 26px;
    }
.a1 {
  max-width: 200vw;
  max-height: 180vh;
  position: absolute;
  z-index: 22;
  /* margin-top: 40px; */
  cursor: move;
}
.a2 {
  max-width: 95vw;
  max-height: 90vh;
  position: absolute;
  z-index: 22;
  /* margin-top: 40px; */
  cursor: move;
}
.zoom-box {
  cursor: zoom-in;
}
.photo_box {
  margin: 0 5px 5px 0;
}
</style>




