import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueTouch from 'vue-touch'
import vDrag from '@/until/drag'

Vue.directive('drag', vDrag)
Vue.config.productionTip = false;
Vue.use(VueTouch, {name: 'v-touch'})
   VueTouch.config.swipe = {
   hreshold: 400 //手指左右滑动距离
};
Vue.directive('pcDrag', {
  //1.指令绑定到元素上回立刻执行bind函数，只执行一次
  //2.每个函数中第一个参数永远是el，表示绑定指令的元素，el参数是原生js对象
  bind: function (el, elementObj, vnode) {
    let dragBox = el; //获取当前元素
    let switchPos = {
      store: {
        x: 0,
        y: 0,
        distance: 1
      },
      transformData: {
        x: 0,
        y: 0,
        scale: 1
      },
    }
    dragBox.onmousedown = e => {
      e.preventDefault();
      //鼠标相对元素的位置
      switchPos.store.x = e.clientX;
      switchPos.store.y = e.clientY;
      document.onmousemove = e => {
        e.preventDefault();
        let x = e.clientX;
        let y = e.clientY;
        let width = vnode.context.viewSizeWidth * vnode.context.params.zoomVal - 100;
        let height = vnode.context.viewSizeHeight / 2;
        switchPos.transformData.x += Math.abs(switchPos.transformData.x + x - switchPos.store.x) >  width ? 0 : x - switchPos.store.x;
        switchPos.transformData.y += Math.abs(switchPos.transformData.y + y - switchPos.store.y) >  height ? 0 : y - switchPos.store.y
        switchPos.store.x = x;
        switchPos.store.y = y;
        //移动当前元素
        el.style.transform = ` translate(${Math.round(switchPos.transformData.x) || 0}px, ${Math.round(switchPos.transformData.y) || 0}px) scale(${vnode.context.params.zoomVal})`;
      };
      document.onmouseup = e => {
        //鼠标弹起来的时候不再移动
        document.onmousemove = null;
        //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）
        document.onmouseup = null;
        // 对外暴露元素相对于父级位置
        // elementObj.value.left = dragBox.style.left;
        // elementObj.value.top = dragBox.style.top;
      };
    };
  }
});
new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
