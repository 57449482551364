<template>
   <div id="app">
      <er-carousel-index v-if="isPc" v-bind:id="id">
      </er-carousel-index>
      <er-carousel-index-mobile v-if="!isPc">
      </er-carousel-index-mobile>
        <!-- <router-view/> -->
   </div>
</template>
<script>
   import ErCarouselIndex from './components/CarouselIndex.vue';
   import ErCarouselIndexMobile from './components/mobileIndex.vue';
   export default {
      name: 'app',
      data() {
         return {
            isPc: true,
            id:1
         }
      },
      components: {
         ErCarouselIndex,//一定要进行组件声明，不然不能引用子组件
         ErCarouselIndexMobile
      },
      mounted() {
         let id = this.$route.query.id;
         this.id = id;
         console.log( this.$route,'id~~~~' )
           if (this._isMobile()) {
                // alert("手机端");
                this.isPc = false;
               //  this.$router.replace(`/m_index/${id}`);
            } else {
               this.isPc = true;
                // alert("pc端");
               //  this.$router.replace(`/pc_index/${id}`);
            }
      },
      methods: {
          _isMobile: function(){
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        }
      }
   }
</script>